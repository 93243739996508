var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-10 text-center"},[_c('v-col',{attrs:{"cols":"3"}})],1),_c('v-row',{staticClass:"mt-10 text-center"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.adminKalamehsFiltered,"items-per-page":20,"footer-props":{
                           'items-per-page-options': [500, 100, 1000, -1 ],
                           }},scopedSlots:_vm._u([{key:"item.kalameh",fn:function(ref){
                           var item = ref.item;
return [_c('h2',[_vm._v(" "+_vm._s(item.kalameh)+" ")])]}},{key:"item.kalamehFarsi",fn:function(ref){
                           var item = ref.item;
return [_c('h2',[_vm._v(" "+_vm._s(item.kalamehFarsi)+" ")])]}},{key:"item.fileName",fn:function(ref){
                           var item = ref.item;
return [_c('h2',[_vm._v(" "+_vm._s(item.kalameh + '.mp3')+" ")])]}},{key:"item.hasAudio",fn:function(ref){
                           var item = ref.item;
return [(item.kalamehAudio.data)?_c('h2',{style:('background-color:' + ( item.kalamehAudio.data ? 'gray' : 'red'))},[_c('audio',{attrs:{"controls":""}},[_c('source',{attrs:{"src":'/audios/kalamehs/' + item.kalameh+ '.mp3'}})])]):_vm._e()]}},{key:"item.darsInfo",fn:function(ref){
                           var item = ref.item;
return [_c('h2',{class:_vm.pickColor(item.darsHarfIdx)},[_vm._v(" "+_vm._s(item.darsHarf)+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }