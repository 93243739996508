<template>
   <v-container class="px-0" >
      <v-row style="background-color:white;height:500px" id="rookhani" v-if="!currDars || !currDars.kalameh" class="col-12" no-gutters>
         <div style="background-color:white">
            <div>
<h1 class="shekkar_aval_05_14" lang="fa-IR" >
   Hello
 </h1>
   <input class="input" />
   <div class="simple-keyboard"></div>
            </div>
 <!--
         <div>
<h1 class="shekkar_aval_05_14" lang="fa-IR" >
   آب , بابا , آباد , آداب , باد , بَد 
 , مِلّی , دِقَّت , شِدَّت , مُدَّت , بَر
 </h1>
         </div>
         -->
         </div>
   </v-row>
</v-container></template>

<script>
import pn from 'persian-number';
//import store from '../store';
import Vuex from 'vuex'
import axios from 'axios';
import Keyboard from 'simple-keyboard';


export default {
   name: 'Takhte',
   components: {
   },
   data: function () {
      return {
         //mdiMenu ,
         height: 0,
         noRender: false,
         //darsId: null,
         email: '',
         code: '',
         //selectedDarsId: '',
         darsDone: false, // 
         window,
         //goToDarses: [], 
    }
  },
  async mounted() {
     const keyboard = new Keyboard({
  onChange: input => onChange(input),
  onKeyPress: button => onKeyPress(button)
});
     console.log(keyboard)

function onChange(input){
  document.querySelector(".input").value = input;
  console.log("Input changed", input);
}

function onKeyPress(button){
  console.log("Button pressed", button);
}
  },
  computed: { 

     ...Vuex.mapState("student",[ 
        "numHarfLearned",
        "kelasDarsId",
        "student",
        "currDarsId",
        "currDars",
        "audioDars",
        "studentKelasId",
     ]),
     ...Vuex.mapState("harf",[ 
        "horoof",
        "audioAlph",
        "imgAlph",
        "harfFormsHier",
        "harfSoundsAll",
     ]),
     ...Vuex.mapState("kelas",[ 
        "kelas",
        "kelasAudios",
     ]),
     ...Vuex.mapGetters("student",[
        'darsKalameh',
        'darsHoroof',
        'darsHarfKeys',
        //'darsHarfForms',
        // ...
     ]),
     ...Vuex.mapGetters("kelas",[
        'kelasStudents',
        "filteredKelasAudios"
     ]),
     ...Vuex.mapState("auth",[
        'backendHost',
     ]),
     ...Vuex.mapGetters("auth",[
        'headerConfig',
     ]),
     compHeight() {
        switch (this.$vuetify.breakpoint.name) {
           case 'xs': return 100 
           case 'sm': return 150
           case 'md': return 170 
           case 'lg': return 200 
           case 'xl': return 200
        }
        return 200
     },
     harfStart () {
      if ( !this.horoof) return null
      if ( !this.currDars || !this.currDars.startOfHarves) return null
      return this.horoof.find( harf => harf.harfF === this.currDars.startOfHarves[0])
     },
    //[ 
    // { harfForm1: [ harfFor2, harfForm2]}  
    //]
   //  harfStartHarfForms () {
   //  if (!this.harfStart) return []
   //  const harfForms = this.harfStart.harfForms.reduce((tot,harfForm,idx) => {
   //     const harfGroup =  this.harfStart.harfGroups[idx]
   //     if ( harfGroup  === 'self' ) {
   //        const o = {}
   //        o[harfForm] = []
   //        tot.push(o)
   //     } else {
   //        tot[tot.length-1][harfGroup].push(harfForm)
   //     } 
   //     return tot
   //  },[])
   //  return harfForms
     //return this.harfStart.harfForms.filter ( (harfForm,idx) => {
     //   if ( this.harfStart.harfGroups[idx] === 'self' 
     //    // && (
     //    //    harfForm.includes("koochik") ||
     //    //    harfForm.includes("chasban") ||
     //    //    harfForm.includes("bikola"))
     //   ) return true
     //   return false
     //}) 

   //  },


  },
  //
  methods: {
     ...Vuex.mapActions("student",[
        "getStudentKelas",
        "getDars",
        "getNumHarfLearned",
        //"incrStuDarsId",
     ]),
     ...Vuex.mapActions("kelas",[
        "getKelas",
        "getKelasAudios"
     ]),

     ...Vuex.mapActions("harf",[
        "getHoroof",
     ]),
     changeToGif(x,audioId) {
        document.getElementById(audioId).play()
        const oldSrc = x.target.src
        const newSrc =  oldSrc.replace(".png", ".gif") 
        console.log(newSrc)
        x.target.src = `/gifs/${x.target.id}.gif`
      },
     playRecordedAudio(id) {
        document.getElementById('audio'+id).play()
     },
     async blobToBase64(blob) {
        return new Promise((resolve) => {
           const reader = new FileReader();
           reader.onloadend = () => resolve(reader.result.split(',')[1]);
           reader.readAsDataURL(blob);
        });
     },
     async callback(data) {
        console.debug(data);

        //await this.cleanUpdateCanvas()
        //return
        if (data && data.url) {

           console.log("Here")
           //console.log(data.blob)
           const audioData = await this.blobToBase64(data.blob)
            let newblob = new Blob([ new Buffer(audioData, 'base64')], { type: 'audio/mp3' });
           const newaudioData = await this.blobToBase64(newblob)
           console.log(newblob)
           //console.log(audioData)
           console.log(newaudioData)
           const payload = { 
              studentId: this.student._id, 
              darsId: this.currDarsId ,
              audioData 
           }
           await axios.post(`${this.backendHost}/api/kelases/${this.kelas._id}/kelasAudios`, payload   , this.headerConfig )
     await this.getKelasAudios({kelasId: this.studentKelasId, darsId: this.currDarsId})
           //    let payload = new FormData() 
           //    payload.append('studentId', this.student._id)
           //    payload.append('darsId', this.studentDarsId)
           //    payload.append('audioData', data.blob)
           //      await axios.post(`${this.backendHost}/api/kelases/${this.kelas._id}/kelasAudios`, payload   , {...this.headerConfig, 'Content-Type' : 'multipart/form-data'  } )

           //for ( let studentId of updatedKelas.kelasAudios[this.studentDarsId] ) {
           //   let blob = new Blob([ new updatedKelas.kelasAudios[this.studentDarsId][studentId], 'base64'], { type: 'autio/mp4' });
           //   window.URL.createObjectURL(blob)
           //}
           //  let blob = new Blob([ new Buffer(data.blob), 'base64'], { type: 'autio/mp4' }) 
           let url =  window.URL.createObjectURL(data.blob)
           this.recordingUrl = url; // window.URL.createObjectURL(data.blob);

           //   // var link = document.createElement("a");
           //   // link.href = this.url;
           //   // link.innerHTML = "download here";
           //   // link.download = `voice_${this.index}.mp3`;
           //   // // link.style = "display: none";
           //   // document.body.appendChild(link);
           //   // link.click();
           //   // console.log("dine");
           //   // window.URL.revokeObjectURL(this.url);
        }
        this.recordMode = false
     },
     toggle_record() {
        this.recording = !this.recording;
     },
     async recordModeToggle() {
        this.recordMode = !this.recordMode
     },
     
     
        
    
   //totalDarses() {
   //  return cons.darses.length
   //},
    pn(num) {
      //return pn.convert(num)
      return pn.convertEnToPe(num)
    },
 
    async goToDars(n) { 
      this.currDarsId = n
      //await this.addDarsAudio()
    },
    
    //audioAlph(aG) {
    //  return '/audios/' + aG['harfSound'] + '.m4a'
    //},
    audioAlphId(aG) {
      return aG['harfSounds'][0]
    },

    
  }
}
</script>

<style>
.disable-btn {
  pointer-events: none;
  opacity: 0.2;
}
a {
    text-decoration: none;
}
.ar-records {
  display: none !important;
}

.ar-player {
  display: none !important;
}

.red-opa {
  #opacity: 0.1;
  #z-index: -10 ;
  visibility: hidden;
   
}
img {
  border: 5px solid #555;
  margin: 3px;
}

.shekkar_aval_05_14 { 
  font-family: 'shekkar_aval_05_14', serif;
}

@font-face {
  font-family: 'shekkar_aval_05_14';
  src: url('../assets/fonts/../fonts/shekkar_aval_05_14.ttf') format('truetype');
}
hr {width:90px;}
h1 {
   text-decoration: underline; 
   text-decoration-thickness: 1px;
   text-decoration-color: rgb(130, 166, 231);
   text-underline-offset: -3px;
   margin-top: 5px;
   margin-bottom:5px;
   
   }

</style>
