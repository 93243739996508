<template>
   <v-container>
      <v-row class="mt-10 text-center">
         <v-data-table
               :headers="headers"
               :items="kelasStudents"
               :items-per-page="20"
               hide-default-header
               class="elevation-1"
               >
               <template v-slot:item.pic="{ item }">
                  <v-img
                        height="100"
                        width="100"
                        :src="item.url"
                        ></v-img>

               </template>
            <!--
               <template v-slot:item.name="{ item }">
               <v-chip
               color="grey lighten-3"

               >
               <span @click="goToStudent(item.code,item.email)"> {{ item.name }} </span>
               </v-chip>
               </template>
            -->
            <!--
               <template v-slot:item.kelas="{ item }">
               {{ kelasIdToNames(item.kelases)  }}
               </template>
            -->
            <template v-slot:top>
               <v-toolbar
                     flat
                     >
                     <v-toolbar-title>  
                        دانش آموزان کلاس
                        {{ kelas }}
                     </v-toolbar-title>
                  <v-divider
                        class="mx-4"
                        inset
                        vertical
                        ></v-divider>
                  <v-spacer></v-spacer>
               </v-toolbar>
            </template>
         </v-data-table>
      </v-row>
   </v-container>
</template>

<script>

//import axios from 'axios'
import { mdiHome } from '@mdi/js';
import Vuex from 'vuex'

export default {
  name: 'User',
  data: function () {
    return {
      students: [],
      kelas: "",
      //headers: "pic studentId name naam kelas darsId".split(' ').map( a => {  return { 'text': a, 'value': a }} ),
      headers: "pic name naam darsId".split(' ').map( a => {  return { 'text': a, 'value': a }} ),
      mdiHome,
    }
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },
  async mounted() {

     this.changeRTL()
     await this.getStudent({});
     await this.getKelas({kelasId: this.studentKelasId})
     //const kelasIndex = this.$route.query.kelasIndex || 0
     //this.kelas =  this.student.kelases[kelasIndex].kelasName
     //if ( kelasIndex ) {
     //  res = (await axios.get(`${this.backendHost}/api/students?kelasIndex=${kelasIndex}` , this.headerConfig))
     //} else {
     //  res = (await axios.get(`${this.backendHost}/api/students` , this.headerConfig))
     //}
    // let res = (await axios.get(`${this.backendHost}/api/students` , this.headerConfig))
    //this.students = res.data
    //let blob,url;
    //for ( let student of this.students ) {
    //   if ( student.profileImage ) {
    //      blob = new Blob([ new Buffer(student.profileImage.data, 'base64')], { type: 'image/jpg' });
    //      url = window.URL.createObjectURL(blob)
    //      student.url = url
    //   }
    //}
    //res = (await axios.get(`${this.backendHost}/api/kelases`, this.headerConfig))
    //this.kelases = res.data
    //this.kelasNames = this.kelases.map( a=>a.kelasName )

    //console.log(res)
  },

   computed: { 
      ...Vuex.mapState("student",[ 
         "kelases",
         "student",
         "studentKelasId",
      ]),
      ...Vuex.mapState("auth",[ 
         "backendHost",
      ]),
      ...Vuex.mapGetters("auth",[ 
         "headerConfig",
      ]),
      ...Vuex.mapGetters("kelas",[ 
         "kelasStudents",
      ]),
      ...Vuex.mapGetters("student",[ 
      ]),
    
    formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
    
    
  },
  methods: { 
     ...Vuex.mapActions("student",[
        "getStudent",
        "getStudents",
        "getDars",
        "getKelases",
        "getHoroof",
        "setDarsId",
     ]),
     ...Vuex.mapActions("kelas",[
        "getKelases",
        "getKelas",
     ]),
    changeRTL () {
      this.$vuetify.rtl = true
    },

    onFileInfo(file) {
      console.log(file)
    },
   //kelasIdToNames ( ids ) {
   //  console.log(ids)
   //  if ( !ids || !this.kelases ) return []
   //  return ids.filter(a=> a).map ( k  => { 
   //    const studentKelas = this.kelases.find( j => j._id == k )
   //    if (!studentKelas ) return ''
   //    console.log(studentKelas )
   //    return studentKelas.kelasName 
   //  })
   //},
    
  }
}
</script>
