<template>
   <v-container v-if="horoof[0]" fluid>
      <!--
      <img @click="changeToGifOld" :src="imageSrc" > 
      <img src="/images2/gifs/be_koochik_chap.gif" > 
      <img src="/images2/gifs/be_koochik_chap_l.gif" > 
      -->
      <br>

      <div v-for="harf in horoof" :key="harf._id" >
         <div v-if="initialTrue || showHarf[harf.harfLead]">
            <img  v-for="harfKey in harf.harfKeys" @click="changeToGif($event,harf)" :id="harf.harfLead" 
                  :key="harfKey" v-bind:src="gifPath[harf.harfLead][harfKey]" > 
         </div>
         <div style="padding:300px"> </div>
      </div>

   </v-container>
</template>

<script>

import Vuex from 'vuex'

export default {
   name: 'ShowGifsAll',
   data: function () {
      return {
         imageSrc: "/gifs/be_koochik_chap.png",
         showHarf: {},
         initialTrue: true
      }
   },
   beforeRouteUpdate(to,from,next) {
      console.log(`R2L : ${this.$vuetify.rtl}`);
      this.$vuetify.rtl = false 
      next()
   },
   watch: {
   },
   async mounted() {
      this.$vuetify.rtl = true
      console.log(`R2L : ${this.$vuetify.rtl}`);
      console.log(process.env)
      this.$vuetify.rtl = false 
      await this.getHoroof()
      for ( let harf of this.horoof ) {
         this.showHarf[harf.harfLead] = true;
      }
      //const email = "yazdan.aghaghiri@gmail.com"
      //const code = "1111"
      //await this.login({code,email})
     //await this.getStudent({});

   },
   computed: { 
      ...Vuex.mapState("harf",[ 
         "horoof",
         "imgAlph",
      ]),
     gifPath() {
        return this.horoof.reduce((tot,harf) => {
           tot[harf.harfLead] = tot[harf.harfLead] || {}
           for ( let harfKey of harf.harfKeys ) {
              tot[harf.harfLead][harfKey] = '/gifs/'+harfKey+'.gif'
           }
           return tot
        }, {})
    
     }
   },
   methods: { 
      changeToGifOld() {
         console.log("here")
        this.imageSrc="/gifs/be_koochik_chap.gif" 
      },
     changeToGif(x,harf) {
        this.initialTrue = false
        this.showHarf[harf.harfLead] = false
        setTimeout(() => {
           this.showHarf[harf.harfLead] = true
           this.showHarf = Object.assign({},this.showHarf)
        }, "500")
        //this.showHarf[harf.harfLead] = true
      // console.log(x)
      // const elems = document.getElementById(harf.harfLead)
      // console.log(elems)
      // console.log(harf)
      // for ( const elem in elems ) {
      //    const oldSrc = elem.getAttribute("src")
      //    console.log(oldSrc)
      //    const newSrc =  oldSrc.replace(".png", ".gif") 
      //    console.log(newSrc)
      //    elem.setAttribute("src", `/images2/gifs/${elem.id}.gif`)
      // }
     },
     //changeToGif(x) {
     //   const oldSrc = x.target.src
     //   const newSrc =  oldSrc.replace(".png", ".gif") 
     //   console.log(newSrc)
     //   x.target.src = `/images2/gifs/${x.target.id}.gif`
     //},
      async changeToGifKey(x,harfForm) {
         const origSrc = x.target.src
         x.target.src = `/gifs/${harfForm}.gif`
         setTimeout(() => {
            x.target.src = origSrc 
         }, "5000")

      },
     ...Vuex.mapActions("student",[
        "getStudent",
        ]),
      ...Vuex.mapActions("harf",[
         "getHoroof",
      ]),
      ...Vuex.mapActions("auth",[
         "login",
      ]),
   }
}
</script>

<style scoped>
img {
  border: 5px solid #555;
}
</style>
