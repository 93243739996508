<template>
   <v-container>
      <v-row class="mt-10 text-center">
         <v-col cols="3">
            <v-select class="mt-2" :items="pageItems" item-text="text" item-value="value" background-color="blue lighten-1"
               v-model="selKelas" solo>
               <!--
                                      <template v-slot:label>
                                      <p> {{ kelas }} </p>
                                      </template>
                                   -->
            </v-select>
         </v-col>
         </v-row>


      <v-row class="mt-10 text-center">
         <v-alert color="purple" closable="true" v-if="apiMessage" type="apiMsgType">
            {{ apiMessage  }}
         </v-alert>
         <v-data-table
               :headers="headers"
               :items="adminStudents"
               :items-per-page=20
               :footer-props="{
                              'items-per-page-options': [50, 100, 200, -1 ],
                              'items-per-page': 50,
                              }"
               class="elevation-1"
               >
               <template v-slot:item.pic="{ item }">
                  <v-img
                        height="100"
                        width="100"
                        :src="item.url"
                        ></v-img>

               </template>
            <template v-slot:item.fname="{ item }">
                     <span> {{ item.fname }} </span>
            </template>
            <template v-slot:item.lname="{ item }">
                     <span> {{ item.lname }} </span>
            </template>
            <template v-slot:item.valid="{ item }">
                     <span> {{ item.valid }} </span>
            </template>
            <template v-slot:item.verified="{ item }">
                     <span> {{ item.verified }} </span>
            </template>
            <!--
            <template v-slot:item.kelas_darsId="{ item }">
               <v-btn 
                      v-for="[idx,k] of kelasIdToNames(item.kelases).entries()" :key="idx"
                      @click="goToStudent(item.code,item.email,item.kelases[idx])"  
                      >
                  {{ k }} :  {{ getKelasDarsId(item,idx)   }} 
               </v-btn>
            </template>
         -->
            <template v-slot:item.kelases="{ item }">
               <div v-for="[idx,stuKel] of item.stuKelases.entries()" :key="idx">
            
                  <v-btn v-if="stuKel.valid" @click="goToStudent(item.studentId,item.email,stuKel.kelas._id)">
                     {{stuKel.kelas.kelasName}}: {{stuKel.darsId}}
                  </v-btn>
                  <v-icon v-if="stuKel.valid" small class="mr-2" @click="editItemInKelas(item,stuKel)">
                     mdi-pencil
                  </v-icon>
               </div>
               <!--
               {{ item.stuKelases ? item.stuKelases.filter(sk => sk.valid).map( a => a.kelas.kelasName) : [] }}
               <v-chip 
                      v-for="[idx,k] of item.kelasDarsIds.entries()" :key="idx"
                      >
                      {{ k.darsId }} : {{ k.kelasId }}
               </v-chip>
               -->
            </template>


            <template v-slot:top>
               <v-toolbar
                     flat
                     >
                     <v-toolbar-title> student list </v-toolbar-title>
                     <v-divider
                           class="mx-4"
                           inset
                           vertical
                           ></v-divider>
                     <v-spacer></v-spacer>
                     <v-dialog
                           v-model="dialogDars"
                           max-width="700px"
                           >
                           
                        <v-card>
                           <v-card-title>
                              <span class="headline">{{ formTitleDars }}</span>
                           </v-card-title>

                           <v-card-text>
                              <v-container>
                                 <v-row>
                                       <v-col cols="12" sm="6" md="4">
                                          <v-text-field v-model="editedStuKel.darsId" label="Dars Id">
                                          </v-text-field>
                                       </v-col>
                                 </v-row>
                              </v-container>
                           </v-card-text>

                           <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="closeDars"
                                    >
                                    Cancel
                              </v-btn>
                              
                                 <v-btn
                                       color="blue darken-1"
                                       text
                                       @click="saveDarsId"
                                       >
                                       Save
                                 </v-btn>
                           </v-card-actions>
                        </v-card>
                     </v-dialog>
                     <v-dialog
                           v-model="dialog"
                           max-width="700px"
                           >
                           <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                    color="blue lighten-2"
                                    dark
                                    class="mb-2"
                                    v-bind="attrs"
                                    v-on="on"
                                    >
                                    Add Student
                              </v-btn>
                           </template>
                        <v-card>
                           <v-card-title>
                              <span class="headline">{{ formTitle }}</span>
                           </v-card-title>

                           <v-card-text>
                              <v-container>
                                 <v-row>
                                    <v-col
                                          cols="12"
                                          >
                                          <v-image-input
                                                v-model="imageData"
                                                :image-quality="0.85"
                                                :name="'hajoo'"
                                                clearable
                                                image-format="jpeg"
                                                @file-info="onFileInfo"
                                                />

                                    </v-col>
                                 </v-row>
                                 <v-row>
                                    <v-col v-if="editedIndex != -1"
                                           cols="12"
                                           sm="6"
                                           md="4"
                                           >
                                           <v-text-field 
                                           v-model="editedItem.studentId"
                                           label="Student ID"
                                           >
                                           </v-text-field>
                                    </v-col>

                                    <v-col
                                          cols="12"
                                          sm="6"
                                          md="4"
                                          >
                                          <v-text-field
                                                v-model="editedItem.fname"
                                                label="Name"
                                                >
                                          </v-text-field>
                                    </v-col>
                                    <v-col
                                          cols="12"
                                          sm="6"
                                          md="4"
                                          >
                                          <v-text-field
                                                v-model="editedItem.lfname"
                                                label="Last Name"
                                                >
                                          </v-text-field>
                                    </v-col>
                                    <v-col
                                          cols="12"
                                          sm="6"
                                          md="4"
                                          >
                                          <v-text-field
                                                v-model="editedItem.fnaam"
                                                label= "نام"
                                                >
                                          </v-text-field>
                                    </v-col>
                                     <v-col
                                          cols="12"
                                          sm="6"
                                          md="4"
                                          >
                                          <v-text-field
                                                v-model="editedItem.lnaam"
                                                label= "نام خانوادگی "
                                                >
                                          </v-text-field>
                                    </v-col>          
                                             <v-col 
                                                   cols="12"
                                                   sm="6"
                                                   md="4"
                                                   >
                                                   <v-text-field
                                                         v-model="editedItem.email"
                                                         label="email"
                                                         ></v-text-field>
                                             </v-col>
                                                <v-col
                                                      cols="12"
                                                      sm="6"
                                                      md="4"
                                                      >
                                                      <v-text-field
                                                            v-model="editedItem.code"
                                                            label="code"
                                                            ></v-text-field>
                                                </v-col>
                                                   <v-col
                                                         cols="12"
                                                         sm="6"
                                                         md="4"
                                                         >
                                                         <v-boolean-field
                                                               v-model="editedItem.valid"
                                                               label="Valid"
                                                               ></v-boolean-field>
                                                   </v-col>
                                                   <v-col
                                                         cols="12"
                                                         sm="6"
                                                         md="4"
                                                         >
                                                         <v-text-field
                                                               v-model="editedItem.verified"
                                                               label="Verified"
                                                               ></v-text-field>
                                                   </v-col>
                                                   <v-col
                                                         cols="12"
                                                         sm="6"
                                                         md="4"
                                                         >
                                                         <v-select
                                                               :items="kelasItems"
                                                               item-text="text"
                                                               item-value="value"
                                                               v-model="editedItem.kelases"
                                                               label="classes"
                                                               multiple
                                                               ></v-select>
                                                   </v-col>
                                                      
                                                   <!--
                                                      <v-col
                                                            cols="12"
                                                            sm="6"
                                                            md="4"
                                                            >

                                                            <v-text-field
                                                                  v-model="editedItem.kelasDarsId.darsId" 
                                                                  label="dars"
                                                                  ></v-text-field>
                                                      </v-col>
                                                   -->

                                 </v-row>
                              </v-container>
                           </v-card-text>

                           <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="close"
                                    >
                                    Cancel
                              </v-btn>
                              
                                 <v-btn
                                       color="blue darken-1"
                                       text
                                       @click="save"
                                       >
                                       Save
                                 </v-btn>
                           </v-card-actions>
                        </v-card>
                     </v-dialog>

                     <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                           <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
                           <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                              <v-btn color="red darken-1" text @click="purgeItemConfirm">Purge</v-btn>
                              <v-spacer></v-spacer>
                           </v-card-actions>
                        </v-card>
                     </v-dialog>
               </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
               <v-icon
                     small
                     class="mr-2"
                     @click="editItem(item)"
                     >
                     mdi-pencil
               </v-icon>
                  <v-icon
                        small
                        @click="deleteItem(item)"
                        >
                        mdi-delete
                  </v-icon>
            </template>
            <!--
               <template v-slot:no-data>
               <v-btn
               color="primary"
               @click="initialize"
               >
               Reset
               </v-btn>
               </template>
            -->
         </v-data-table>
      </v-row>
   </v-container>
</template>

<script>

import axios from 'axios'
import { mdiHome } from '@mdi/js';
import Vuex from 'vuex'
import Lodash from 'lodash'                  

export default {
   name: 'AdminStudents',
   data: function () {
      return {
         imageData: "",
         apiMessage: "",
         apiMsgType: "",
         dialog: false,
         dialogDars: false,
         dialogDelete: false,
         selKelas: null,
         pageItems: [],
         kelasItems: [],
         headers: "pic studentId valid verified fname lname fnaam lnaam kelases email actions".split(' ').map( a => {  return { 'text': a, 'value': a }} ),
         editedIndex: -1,
         mdiHome,
         editedStuKel: {
            darsId: -1
         },
         editedItem: {
            name: '',
            naam: '',
            darsId: 0,
            email: '',
            code: 0,
            profileImage: "", 
            needToSave: false,
            //kelasDarsId: { kelasId: null, darsId : null }
         },
      }
   },
   beforeRouteUpdate(to,from,next) {
      console.log(`R2L : ${this.$vuetify.rtl}`);
      this.$vuetify.rtl = false 
      next()
   },
   watch: {
     async selKelas (val ) {
        return await this.getKelas({kelasId: val})
     },
      dialog (val) {
         val || this.close()
      },
      dialogDars (val) {
         val || this.close()
      },
      dialogDelete (val) {
         val || this.closeDelete()
      },
   },
    
   async mounted() {
      window.vuetify = this.$vuetify
      console.log(`R2L : ${this.$vuetify.rtl}`);
      console.log(process.env)
      this.changeRTL()
      
      //this.$vuetify.rtl = false 
      const email = "yazdan.aghaghiri@gmail.com"
      const code = "hello"
      await this.login({code,email})
      await this.getKelases()
      this.pageItems = [1, 2 ,3]
      this.kelasItems = this.adminKelases.
         map( a=> { return { text: a.kelasName, value: a._id}} ) //.concat({ text: "no class", value: null})
      //concat({text: 'همه کلاس‌ها', value: 'all'})
      this.getStudents()
         //map( a=> { return { text: a.kelasName, value: a._id}} )
      this.$nextTick(() => {
         console.log('test') // runs multiple times
         this.$vuetify.rtl = false
      }, 250) // increase to ur needs
   },
   computed: { 
      ...Vuex.mapState("student",[
         "studentKelasId",
      ]),
      ...Vuex.mapState("admin",[
         "adminKelases",
         "adminStudents",
      ]),
      ...Vuex.mapGetters("kelas",[
         "kelasStudents",
      ]),
      ...Vuex.mapState("kelas",[
         "kelas"
      ]),
      ...Vuex.mapGetters("auth",[
         "headerConfig",
      ]),
      ...Vuex.mapState("auth",[
         "backendHost",
      ]),
      formTitle () {
         return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },
      formTitleDars () {
         const name = Lodash.get(this.editedItem, "name")
         const kelasId = Lodash.get(this.editedStuKel, "kelas.kelasName")
         return `Edit Dars(student Kelas) for ${name} ${kelasId} ` 
      },
     //adminStudentKelases(student) {
     //   return student.stuKelases.filter(sk => sk.valid).map(sk => {
     //      return {
     //         kelasName: sk.kelas.kelasName,
     //         kelasId: sk.kelas._id,
     //         darsId: sk.darsId
     //      }
     //   })
//   //       item.stuKelases ? item.stuKelases.filter(sk => sk.valid).map( a => a.kelas.kelasName) : [] }}
     //},
      

   },
   methods: { 
      ...Vuex.mapActions("auth",[
         "login", "adminLogin",
      ]),
      ...Vuex.mapActions("admin",[
        "getStudents",
        "getKelases",
      ]),
      ...Vuex.mapActions("kelas",[
         "getKelas",
      ]),
      onFileInfo(file) {
         console.log(file)
      },
      changeRTL() {
         this.$vuetify.rtl = false
      },
      getKelasDarsId(item,idx) {
         if (!item.kelasDarsIds ) return "N/A"
         return Lodash.get(item.kelasDarsIds.find(k => k.kelasId === item.kelases[idx] ),"darsId","N/A")
      },
      kelasIdToNames ( ids ) {
         console.log(ids)
         if ( !ids || !this.adminKelases ) return []
         return ids.filter(a=> a).map ( k  => { 
            const studentKelas = this.adminKelases.find( j => j._id == k )
            if (!studentKelas ) return ''
            console.log(studentKelas )
            return studentKelas.kelasName 
         })
      },
      
      async goToStudent(studentId,email,kelasId) {

         await this.adminLogin({studentId,email,kelasId})
         await this.$router.push({name: 'darses',params: {darsId:'latest'}})
         //let routeData = this.$router.resolve({name: 'darses', params: {darsId: "latest"}});
         //window.open(routeData.href, '_blank');

      },
     //editItemInKelas (item,stuKel) {
     //   this.getKelas({kelasId:stuKel.kelasId})
     //   this.editedIndex = this.kelasStudents.findIndex(ks => ks._id == item._id )
     //   this.editedItem = Object.assign({kelasDarsId: {kelasId: null, darsId: -1}}, item)
     //   //this.imageData = `data:image/jpeg;base64,${item.profileImage.data}` 
     //   this.editedItem.kelasDarsId.kelasId = this.kelas._id 
     //   this.editedItem.kelasDarsId.darsId = darsId 
     //   console.log(this.editedItem)
     //   if ( this.editedItem.profileImage ) {
     //      // this.editedItem.profileImage = "data:image/jpeg;base64," + this.editedItem.profileImage.data
     //   }
     //   this.dialogDars = true
     //},
      editItemInKelas( item, stuKel) {
         this.editedIndex = this.adminStudents.indexOf(item)
         this.editedItem = Object.assign({}, item)
         this.editedStuKel = stuKel
         this.dialogDars = true

      },
      editItem (item) {
         this.editedIndex = this.adminStudents.indexOf(item)
         this.editedItem = Object.assign({}, item)
         //this.editedItem.kelases = this.editedItem.kelases.map( j => this.kelases.find( k => k._id === j ).kelasName )
         this.editedItem.kelases = this.editedItem.stuKelases.filter(a=>a.valid).map( j => j.kelas._id )
         console.log(this.editedItem)
         if ( this.editedItem.profileImage ) {
             this.imageData = "data:image/jpeg;base64," + this.editedItem.profileImage.data
         }
         this.dialog = true
      },

      deleteItem (item) {
         this.editedIndex = this.adminStudents.indexOf(item)
         this.editedItem = Object.assign({}, item)
         this.dialogDelete = true
      },

      async deleteItemConfirm () {
         const student = this.adminStudents.splice(this.editedIndex, 1)[0]
         console.log(student)
         await axios.delete(`${this.backendHost}/api/students/${student._id}`, this.headerConfig )
         this.closeDelete()
      },
      async purgeItemConfirm () {
         try {
            const student = this.adminStudents.splice(this.editedIndex, 1)[0]
            const name = student.name 
            const resp = await axios.delete(`${this.backendHost}/api/students/${student._id}?purge=true`, this.headerConfig)
            this.closeDelete()
            this.apiMessage = ` Purged ${name}. Num of deleted student Kelases: ${resp.data.numDeletedStudentKelas}`
            this.apiMsgType = "success"
         } catch (err) {
            this.apiMessage = err.msg
            this.apiMsgType = "error"
         }
      },
      closeDars() {
         this.dialogDars = false
         this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
         })
      },
      close () {
         this.dialog = false
         this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
         })
      },

      closeDelete () {
         this.dialogDelete = false
         this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
         })
      },

      //async const addStudentToKelas = function(studentId, kelas) {
      //   return db.student.findByIdAndUpdate(
      //      tutorialId,
      //      { $push: { tags: tag._id } },
      //      { new: true, useFindAndModify: false }
      //   );
      //},

      async save () {
         //this.editedItem.needToSave = true
         const student = this.editedItem
         //const kelasIds = student.kelases.map(k => this.kelases.find(j => j.kelasName === k )).filter(k=>k)
         //student.kelases = kelasIds 
         if (this.editedIndex > -1) {
            console.log(student.name)
            //student.darsId = parseInt(student.darsId)
            student.studentId = parseInt(student.studentId)
           // student.kelases = student.kelases(id => ObjectID(id))
            if ( this.imageData ) {
               student.profileImage = { contentType: "image/jpeg", data: this.imageData.split(',')[1] }
            }
            let res = await axios.put(`${this.backendHost}/api/students/${student._id}`,  student, this.headerConfig )
            res = await axios.put(`${this.backendHost}/api/students/${student._id}/kelases`,  student, this.headerConfig )
            if ( res.status === 200 ) {
               //Object.assign(this.students[this.editedIndex], student)
               //this.students[this.editedIndex ] = student
               //this.students = Object.assign({}, this.students )
               let res = (await axios.get(`${this.backendHost}/api/students?noFilterClass=1`, this.headerConfig))
               this.students = res.data
            }
         } else {
            console.log(student)
            if ( this.imageData ) {
               student.profileImage = { contentType: "image/jpeg", data: this.imageData.split(',')[1] }
            }
            const res = await axios.post(`${this.backendHost}/api/students?updateKelas=1`,  student , this.headerConfig )
            if ( res.status === 200 ) {
               //this.students.push(this.editedItem)
               //this.students = Object.assign({}, this.students )
               let res = (await axios.get(`${this.backendHost}/api/students?noFilterClass=1`, this.headerConfig))
               this.students = res.data
            }
         }
         this.dialog = false
      },
      async saveDarsId () {
         const student = this.editedItem
         let stuKelas = this.editedStuKel
         let res = await axios.put(`${this.backendHost}/api/students/${student._id}/studentKelas/${stuKelas._id}`, stuKelas, this.headerConfig)
         if (res.status === 200) {
            stuKelas = res.data
         }
         this.dialogDars = false
      },
      async saveAll() {
         //let students = this.students.filter( s => s.needToSave )
         // temporariliy save everything
         let students = this.students
         for ( let student of students ) { 
            student.darsId = parseInt(student.darsId)
         }
         console.log(students)
         await axios.post(`${this.backendHost}/api/students-update`, { students })
      }
   }
}
</script>
