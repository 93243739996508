import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';

Vue.use(Vuex)

const state=  {
     accessToken: localStorage.getItem('user-token') || '',
     studentId: localStorage.getItem('student-id') || null,
     kelasId: localStorage.getItem('kelas-id') || null,
     backendHost: process.env.NODE_ENV === 'development' ? 'https://192.168.1.78:3085'  : '', 
  }
const mutations= {
   accessToken(state, newToken) {
      state.accessToken = newToken
      localStorage.setItem('user-token', newToken)
   },
   studentId(state, studentId) {
      state.studentId = studentId
      localStorage.setItem('student-id', studentId)
   },
   kelasId(state, kelasId) {
      state.kelasId = kelasId
      localStorage.setItem('kelas-id', kelasId)
   },
}
const actions= {
   async login({commit,getters},params) {
      try {
         console.log(params)
         const email = params.email
         const code = params.code
         const kelasId = params.kelasId
         const res = (await axios.post(`${state.backendHost}/api/login`, { email, code, kelasId }, getters.headerConfig))
         //console.log(res.status)
         commit("accessToken", res.data.data.accessToken)
         commit("studentId", res.data.data.studentId)
         commit("kelasId", res.data.data.kelasId)
         return res

      } catch (e) {
         //console.log(e.response)
         return (e.response)
      }
   },
   async logout({commit}) {
      try {
         //console.log(res.status)
         commit("accessToken", null)
         commit("studentId", null)
         commit("kelasId", null)

      } catch (e) {
         return (e.response)
      }
   },
   async register({commit},params) {
      try {
         console.log(params)
         console.log(commit)
         const student = params
         const res = await axios.post(`${state.backendHost}/api/students?register=true`, student, state.headerConfig)
         //console.log(res.status)
         return res

      } catch (e) {
         //console.log(e.response)
         return (e.response)
      }
   },
   async adminLogin({commit,getters},params) {
      try {
         console.log(params)
         const email = params.email
         const studentId = params.studentId
         const kelasId = params.kelasId
         const res = (await axios.post(`${state.backendHost}/api/login?impersonate=1`, { email, studentId, kelasId }, getters.headerConfig))
         //console.log(res.status)
         commit("accessToken", res.data.data.accessToken)
         commit("studentId", res.data.data.studentId)
         commit("kelasId", res.data.data.kelasId)
         return res

      } catch (e) {
         //console.log(e.response)
         return (e.response)
      }
   },
   async getStudent({commit,getters},params) {
      console.log(params)
      console.log(state.studentId)
      const res = await axios.get(`${state.backendHost}/api/students/${state.studentId}`, getters.headerConfig)
      const student = res.data
      let blob = new Blob([ new Buffer(student.profileImage.data, 'base64')], { type: 'image/jpg' });
      let url = window.URL.createObjectURL(blob)
      student.imgProfile  = url
      commit("student", student)
      commit("studentDarsId", parseInt(student['darsId']))
   },

}

const getters = {
   headerConfig() { 
      return {
         headers: { Authorization: `Bearer ${state.accessToken}` }
      }
   },

}

export default{
   namespaced: true,
   state,
   actions,
   mutations,
   getters,
}

