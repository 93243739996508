<template>
   <v-container fluid>
      <!--
      {{ this.$vuetify.rtl }} 
      -->
      <v-row class="mt-10 text-center">
         <v-col
               cols="3"
               >
               <v-select  class="mt-2"
                          :items="kelasItems"
                          item-text="text"
                          item-value="value"
                          background-color="blue lighten-1"
                          v-model="selKelas"
                          solo
                          >
                          <!--
                             <template v-slot:label>
                             <p> {{ kelas }} </p>
                             </template>
                          -->
               </v-select>
         </v-col>
      </v-row>

      <v-row class="mt-10 text-center">
         <v-data-table
               :headers="headers"
               :items="kelasStudents"
               :items-per-page=20
               :footer-props="{
                              'items-per-page-options': [50, 100, 200, -1 ],
                              'items-per-page': 50,
                              }"
               class="elevation-1"
               >
               <template v-slot:item.pic="{ item }">
                  <v-img
                        height="100"
                        width="100"
                        :src="item.url"
                        ></v-img>

               </template>
            <template v-slot:item.name="{ item }">
                     <span> {{ item.name }} </span>
            </template>
            <template v-slot:item.kelas="{ item }">

               <v-btn 
                      @click="goToStudent(item.code,item.email,kelas._id)"  
                      >
                   {{ kelas.kelasName }} : {{ item.darsId}}         
               </v-btn>
            </template>
            <!--
            <template v-slot:item.kelas_darsId="{ item }">
               <v-btn 
                      v-for="[idx,k] of kelasIdToNames(item.kelases).entries()" :key="idx"
                      @click="goToStudent(item.code,item.email,kelasIdToNames[idx])"  
                      >
                  {{ k }} :  {{ item.kelasDarsIds.find(k => k.kelasId === item.kelases[idx]) ?  item.kelasDarsIds.find(k => k.kelasId === item.kelases[idx] ).darsId : "N/A"  }} 
               </v-btn>
            </template>
         -->
            <!--
            <template v-slot:item.darsId="{ item }">
               <v-chip> 
                      {{ item.kelasDarsIds.find(k => k.kelasId === selKelas) ? 
                         item.kelasDarsIds.find(k => k.kelasId === selKelas).darsId : "N/A"  }} 
               </v-chip>
            </template>
            -->

            <template v-slot:top>
               <v-toolbar
                     flat
                     >
                     <v-toolbar-title> student list </v-toolbar-title>
                     <v-divider
                           class="mx-4"
                           inset
                           vertical
                           ></v-divider>
                     <v-spacer></v-spacer>

                     <v-dialog
                           v-model="dialog"
                           max-width="700px"
                           >
                           <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                    color="blue lighten-2"
                                    dark
                                    class="mb-2"
                                    v-bind="attrs"
                                    v-on="on"
                                    >
                                    Add Student
                              </v-btn>
                           </template>
                        <v-card>
                           <v-card-title>
                              <span class="headline">{{ formTitle }}</span>
                           </v-card-title>

                           <v-card-text>
                              <v-container>
                                 <v-row>
                                    <v-col
                                          cols="12"
                                          >
                                          <v-image-input
                                                v-model="imageData"
                                                :image-quality="0.85"
                                                :name="'hajoo'"
                                                clearable
                                                image-format="jpeg"
                                                @file-info="onFileInfo"
                                                />

                                    </v-col>
                                 </v-row>
                                 <v-row>
                                    <v-col v-if="editedIndex != -1"
                                           cols="12"
                                           sm="6"
                                           md="4"
                                           >
                                           <v-text-field 
                                           v-model="editedItem.studentId"
                                           label="Student ID"
                                           >
                                           </v-text-field>
                                    </v-col>

                                       <v-col
                                             cols="12"
                                             sm="6"
                                             md="4"
                                             >
                                             <v-text-field
                                                   v-model="editedItem.name"
                                                   label="English name"
                                                   >
                                             </v-text-field>
                                       </v-col>
                                          <v-col
                                                cols="12"
                                                sm="6"
                                                md="4"
                                                >
                                                <v-text-field
                                                      v-model="editedItem.naam"
                                                      label="Farsi name"
                                                      ></v-text-field>
                                          </v-col>
                                             <v-col 
                                                   cols="12"
                                                   sm="6"
                                                   md="4"
                                                   >
                                                   <v-text-field
                                                         v-model="editedItem.email"
                                                         label="email"
                                                         ></v-text-field>
                                             </v-col>
                                                <v-col
                                                      cols="12"
                                                      sm="6"
                                                      md="4"
                                                      >
                                                      <v-text-field
                                                            v-model="editedItem.code"
                                                            label="code"
                                                            ></v-text-field>
                                                </v-col>
                                                   <v-col
                                                         cols="12"
                                                         sm="6"
                                                         md="4"
                                                         >
                                                         <v-select
                                                               :items="kelasItems"
                                                               item-text="text"
                                                               item-value="value"
                                                               v-model="editedItem.kelases"
                                                               label="classes"
                                                               multiple
                                                               ></v-select>
                                                   </v-col>

                                                      <v-col
                                                            cols="12"
                                                            sm="6"
                                                            md="4"
                                                            >

                                                            <v-text-field
                                                                  v-model="editedItem.kelasDarsId.darsId" 
                                                                  label="dars"
                                                                  ></v-text-field>
                                                      </v-col>
                                                         <v-col
                                                               cols="12"
                                                               sm="6"
                                                               md="4"
                                                               >
                                                               <!--
                                                                  <v-image-input
                                                                  v-model="imageData"
                                                                  :image-quality="0.85"
                                                                  clearable
                                                                  image-format="jpeg"
                                                                  @file-info="onFileInfo"
                                                                  />
                                                               -->
                                                            <!--
                                                               <v-file-input
                                                               v-model="editedItem.imageFile"
                                                               accept="image/*"
                                                               label="Picture"
                                                               ></v-file-input>
                                                            -->

                                                         </v-col>
                                 </v-row>
                              </v-container>
                           </v-card-text>

                           <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="close"
                                    >
                                    Cancel
                              </v-btn>
                                 <v-btn
                                       color="blue darken-1"
                                       text
                                       @click="save"
                                       >
                                       Save
                                 </v-btn>
                           </v-card-actions>
                        </v-card>
                     </v-dialog>

                     <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                           <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
                           <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                              <v-spacer></v-spacer>
                           </v-card-actions>
                        </v-card>
                     </v-dialog>
               </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
               <v-icon
                     small
                     class="mr-2"
                     @click="editItem(item)"
                     >
                     mdi-pencil
               </v-icon>
                  <v-icon
                        small
                        @click="deleteItem(item)"
                        >
                        mdi-delete
                  </v-icon>
            </template>
            <!--
               <template v-slot:no-data>
               <v-btn
               color="primary"
               @click="initialize"
               >
               Reset
               </v-btn>
               </template>
            -->
         </v-data-table>
      </v-row>
      <!--
      <v-btn class="mt-10" color="blue lighten-2" @click="saveAll"> SAVE ALL </v-btn>
      -->
   </v-container>
</template>

<script>

import axios from 'axios'
import { mdiHome } from '@mdi/js';
import Vuex from 'vuex'

export default {
   name: 'AdminKelases',
   data: function () {
      return {
         imageData: "",
         dialog: false,
         dialogDelete: false,
         selKelas: null,
         kelasItems: [],
         headers: "pic studentId name naam kelas email code actions".split(' ').map( a => {  return { 'text': a, 'value': a }} ),
         editedIndex: -1,
         mdiHome,
         editedItem: {
            name: '',
            naam: '',
            darsId: 0,
            //kelasDarsIds: [],
            kelasDarsId: {darsId: -1 , kelasId: null},  // is used for updating kelasDarsIds
            //kelasRef: 0,  // is also used for update kelasDarsIds 
            email: '',
            code: 0,
            profileImage: "", 
            needToSave: false,
         },
      }
   },
   beforeRouteUpdate(to,from,next) {
      console.log(`R2L : ${this.$vuetify.rtl}`);
      this.$vuetify.rtl = false 
      next()
   },
   watch: {
      async selKelas (val ) {
         await this.getKelas({kelasId: val})
      },
      dialog (val) {
         val || this.close()
      },
      dialogDelete (val) {
         val || this.closeDelete()
      },
   },
   async mounted() {
      console.log(`R2L : ${this.$vuetify.rtl}`);
      console.log(process.env)
      this.$vuetify.rtl = false 
      const email = "yazdan.aghaghiri@gmail.com"
      const code = "1111"
      await this.login({code,email})
      await this.getKelases()
      this.kelasItems = this.adminKelases.
         map( a=> { return { text: a.kelasName, value: a._id}} ) //.concat({ text: "no class", value: null})
      //concat({text: 'همه کلاس‌ها', value: 'all'})

   },
   computed: { 
      ...Vuex.mapState("student",[
         "studentKelasId",
      ]),
      ...Vuex.mapState("admin",[
         "adminKelases",
      ]),
      ...Vuex.mapState("kelas",[
         "kelas"
      ]),
      ...Vuex.mapGetters("kelas",[
         "kelasStudents",
      ]),
      ...Vuex.mapGetters("auth",[
         "headerConfig",
      ]),
      ...Vuex.mapState("auth",[
         "backendHost",
      ]),
      formTitle () {
         return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },

   },
   methods: { 
      ...Vuex.mapActions("auth",[
         "login",
      ]),
      ...Vuex.mapActions("kelas",[
         "getKelas",
      ]),
      ...Vuex.mapActions("admin",[
         "getKelases",
      ]),
      onFileInfo(file) {
         console.log(file)
      },
      kelasIdToNames ( ids ) {
         console.log(ids)
         if ( !ids || !this.adminKelases ) return []
         return ids.filter(a=> a).map ( k  => { 
            const studentKelas = this.adminKelases.find( j => j._id == k )
            if (!studentKelas ) return ''
            console.log(studentKelas )
            return studentKelas.kelasName 
         })
      },
      async goToStudent(code,email,kelasId) {

         await this.login({code,email,kelasId})
         //await this.$router.push({name: 'darses',params: {darsId:'latest'}})
         let routeData = this.$router.resolve({name: 'darses', params: {darsId: "latest"}});
         //window.open(routeData.href, '_blank');
         window.open(routeData.href);

      },
      editItem (item) {
         this.editedIndex = this.kelasStudents.indexOf(item)
         this.editedItem = Object.assign({kelasDarsId: {kelasId: null, darsId: -1}}, item)
         //this.imageData = `data:image/jpeg;base64,${item.profileImage.data}` 
         this.editedItem.kelasDarsId.kelasId = this.selKelas 
         //this.editedItem.kelases = this.editedItem.kelases.map( j => this.kelases.find( k => k._id === j ).kelasName )
         console.log(this.editedItem)
         if ( this.editedItem.profileImage ) {
            // this.editedItem.profileImage = "data:image/jpeg;base64," + this.editedItem.profileImage.data
         }
         this.dialog = true
      },

      deleteItem (item) {
         this.editedIndex = this.kelasStudents.indexOf(item)
         this.editedItem = Object.assign({}, item)
         this.dialogDelete = true
      },

      async deleteItemConfirm () {
         const student = this.kelasStudents.splice(this.editedIndex, 1)[0]
         console.log(student)
         await axios.delete(`${this.backendHost}/api/students/${student._id}`, this.headerConfig )
         this.closeDelete()
      },

      close () {
         this.dialog = false
         this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
         })
      },

      closeDelete () {
         this.dialogDelete = false
         this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
         })
      },

      //async const addStudentToKelas = function(studentId, kelas) {
      //   return db.student.findByIdAndUpdate(
      //      tutorialId,
      //      { $push: { tags: tag._id } },
      //      { new: true, useFindAndModify: false }
      //   );
      //},

      async save () {
         //this.editedItem.needToSave = true
         const student = this.editedItem
         //const kelasIds = student.kelases.map(k => this.kelases.find(j => j.kelasName === k )).filter(k=>k)
         //student.kelases = kelasIds 
         if (this.editedIndex > -1) {
            console.log(student)
            student.studentId = parseInt(student.studentId)
            if ( this.imageData ) {
               student.profileImage = { contentType: "image/jpeg", data: this.imageData.split(',')[1] }
            }
            const res = await axios.put(`${this.backendHost}/api/students/${student._id}/kelases`,  student, this.headerConfig )
            //const kelasDarsId = student.kelasDarsIds.find( k =>  k.kelasId == student.kelasRef)
            //kelasDarsId.darsId = parseInt(student.kelasDarsId)
            // if a new kelas is added, add similar entry in kelasDarsIds
           //for (let kelas of student.kelases) {
           //   const kelasDarsId = student.kelasDarsIds.find(k => k.kelasId == kelas._id)
           //   if (!kelasDarsId) {
           //      student.kelasDarsIds.push({kelasId: kelas._id, darsId: 1 })
           //   }
           //}
         
            //const res = await axios.put(`${this.backendHost}/api/students/${student._id}?updateKelas=1`,  student, this.headerConfig )
            if ( res.status === 200 ) {
               //Object.assign(this.students[this.editedIndex], student)
               //this.students[this.editedIndex ] = student
               //this.students = Object.assign({}, this.students )
               let res = (await axios.get(`${this.backendHost}/api/students?noFilterClass=1`, this.headerConfig))
               this.students = res.data
            }
         } else {
            console.log(student)
            if ( this.imageData ) {
               student.profileImage = { contentType: "image/jpeg", data: this.imageData.split(',')[1] }
            }
            const res = await axios.post(`${this.backendHost}/api/students?updateKelas=1`,  { student} , this.headerConfig )
            if ( res.status === 200 ) {
               //this.students.push(this.editedItem)
               //this.students = Object.assign({}, this.students )
               let res = (await axios.get(`${this.backendHost}/api/students?noFilterClass=1`, this.headerConfig))
               this.students = res.data
            }
         }
         this.close()
      },
      async saveAll() {
         //let students = this.students.filter( s => s.needToSave )
         // temporariliy save everything
         let students = this.students
         for ( let student of students ) { 
            student.darsId = parseInt(student.darsId)
         }
         console.log(students)
         await axios.post(`${this.backendHost}/api/students-update`, { students })
      }
   }
}
</script>
