//import Main from './components/Main.vue'
import Darses from './components/Darses.vue'
import Login from './components/Login.vue'
import UserVerif from './components/UserVerif.vue'
import PassReset from './components/PassReset.vue'
import MyKids from './components/MyKids.vue'
import AdminStudents from './components/AdminStudents.vue'
import AdminKelases from './components/AdminKelases.vue'
import AdminKalamehs from './components/AdminKalamehs.vue'
import AllDarses from './components/AllDarses2.vue'
import Alefba from './components/Alefba.vue'
import Takhte from './components/Takhte.vue'
import Kelas from './components/Kelas.vue'
import ShowGifsAll from './components/ShowGifsAll.vue'
import ShowGifsAllInf from './components/ShowGifsAllInf.vue'
import ShowGifs from './components/ShowGifs.vue'
import Land from './components/Land.vue'
import MyAdmin from './components/MyAdmin.vue'
//import Login from './components/Login.vue'

const routes = [
  {
    path: '/',
    component: Land,
    name: 'land' 
  },
  {
    path: '/ui/login',
    component: Login,
    name: 'login' 
  },
  {
    path: '/ui/students/:id/verify',
    component: UserVerif,
    name: 'user_verify' 
  },
  {
    path: '/ui/students/:id/password_reset',
    component: PassReset,
    name: 'password_reset' 
  },
  {
     path: '/ui/mk',
     component: MyKids,
     name: 'mykids' 
  },
  {
     path: '/ui/madm',
     component: MyAdmin,
     name: 'my_admin' 
  },
  {
     path: '/ui/all_darses/:page',
     component: AllDarses,
     name: 'all_darses' 
  },
  {
     path: '/ui/as',
     component: AdminStudents,
     name: 'admin_students' 
  },
  {
     path: '/ui/ak',
     component: AdminKelases,
     name: 'admin_kelases' 
  },
   {
     path: '/ui/aw',
     component: AdminKalamehs,
     name: 'admin_kalamehs' 
  },
   {
     path: '/ui/gifsall',
     component: ShowGifsAll,
     name: 'show_gifs_all' 
  },
   {
     path: '/ui/gifsallinf',
     component: ShowGifsAllInf,
     name: 'show_gifs_all_inf' 
  },
   {
     path: '/ui/gifs',
     component: ShowGifs,
     name: 'show_gifs' 
  },

  {
     path: '/ui/dars/:darsId',
     component: Darses,
     name: 'darses'
  },
  {
    path: '/ui/alefba',
    component: Alefba,
    name: 'alefba'
  },
   {
    path: '/ui/takhte',
    component: Takhte,
    name: 'takhte'
  },
  {
    path: '/ui/kelas',
    component: Kelas,
    name: 'kelas'
  }
]

export default routes
