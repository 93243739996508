<template>
     <!--
  <v-app light>
    <v-toolbar class="white">
     <v-toolbar-side-icon><img :src="imageLink.logo" alt="Vuetify.js" height="100%"></v-toolbar-side-icon>
      <v-toolbar-title class="mx-0" v-text="title"></v-toolbar-title>
      <p>Beta</p>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn flat>Open jobs</v-btn>
        < !--<v-btn flat>Blog</v-btn>-- >
        <v-btn flat class="pink lighten-3">Sign In</v-btn>
      </v-toolbar-items>
    </v-toolbar>
    -->
    <v-content class="ma-0"  >
      <section>
        <v-parallax  src="images/takhte_siah_custom_9.png" height="1000"    >
          <v-layout column align-center justify-center class=" white--text">
              <h2 v-if="this.rtl" class="white--text text--darken-2  ma-2 display-2 text-center" style="font-weight: 900; text-shadow: 1px 2px #000000">
              {{    "مدرسه فارسی شکر" }}
                </h2>
              <h4 v-else class="white--text text--darken-2  ma-2 display-2 text-center" style="font-weight: 700; text-shadow: 1px 2px #000000">
                {{   "SHEKKAR FARSI SCHOOL" }}
                </h4>
            <h2 class="white--text text--lighten-1 subheading ma-3 text-xs-center" style="font-weight: 900; text-shadow: 2px 2px #000000">
              {{ this.rtl ?  
               
              "رایگان برای همه علاقه‌مندان"
              :
              "Free for all"
              }}
              </h2>
            <v-btn class="blue darken-1 mt-5" dark x-large href="/ui/login">
              {{ 
              this.rtl ?
               "وارد شوید"
               : 
               "Enter Shekkar"
              }}
            </v-btn>
          </v-layout>
        </v-parallax>

      </section>
      <!--
      <section style="background-color:yellow">
         <v-layout
               column
               align-center
               justify-center
               style="min-height:400px"
        >
        <div >
           Hello
        </div>
         </v-layout>
      </section>
      -->

      <section>
        <v-layout ma-2 style="min-height:400px"
          column
          align-center
          text-center
              justify-center
        >
          <div v-if="this.rtl"  class="my-5">
             <div  class=" text-lg-center">
                <h1 class="black--text text--darken-3  display-1 text-darken-4 "> 
                   شکرشکن شوند همه طوطیان هند
                </h1>
                <br>

           <h1 class="black--text text--darken-3 display-1"> 
              زین قند پارسی که به بنگاله می رود
           </h1>
           <br>
              <span class=" black--text text--darken-3 ">
               حافظ  
              </span>
            </div>
          </div>
  <div v-else  class="my-5">
               <div  class=" text-lg-center">
                  <h3 class="black--text text--darken-3 display-1 text-darken-4 "> 
                    All the parrots of India will be craking sugar
                  </h3>
             <br>
             <h3 class="black--text text--darken-3 display-1 "> 
                  Through this sugar of Parsi which goes to Bengal
             </h3>
             <br>
                <span class=" black--text text--darken-3 ">
                  Hāfez (14th Century)
                </span>
              </div>
            </div>
          </v-layout>
      </section>
      <section style="min-height:400px">
         <v-layout>
          <v-flex xs12>
            <v-container grid-list-xl>
              <v-layout row wrap >
                <v-flex xs12 md4>
                  <v-card class="elevation-0 ">
                    <v-card-text class="text-xs-center">
                      <v-icon x-large class="blue--text text--lighten-2">
                       {{ mdiShareVariant }}
                      </v-icon>
                    </v-card-text>
                    <v-card-title primary-title class="layout justify-center">
                      <div class="headline text-xs-center">
                        {{  
                        this.rtl ?
                         "با دوستان"
                         :
                         "WITH FRIENDS"
                        }}

                      </div>

                    </v-card-title>
                    <v-card-text>

                        {{
                          this.rtl ?
                          "فارسی را به همراه دوستانتان بیاموزید. پیشرفتتان رو با یکدیگر به اشتراک بگذارید"
                          :
                          "Learn Farsi with your friends. Share your progress with each other"
                        }}
                    </v-card-text>
                  </v-card>
                </v-flex>
                <v-flex xs12 md4>
                  <v-card class="elevation-0 ">
                    <v-card-text class="text-xs-center">
                      <v-icon x-large class="blue--text text--lighten-2"> 
                         {{ mdiEyeCheckOutline }}
                      </v-icon>
                    </v-card-text>
                    <v-card-title primary-title class="layout justify-center">
                      <div class="headline">
                        {{ this.rtl ?  
                         "آموزش دیداری"
                         :
                         "VISUAL LEARNING"
                        }}

                      </div>
                    </v-card-title>
                    <v-card-text>
                      
                        {{ this.rtl ?
                          "هدف شکر آموزش دیداری میباشد. کودکان و بزرگسالان میتوانند با استفاده از شکر به تنهایی فارسی را یاد بگیرند"
                          :
                          "Shekkar's goal is teach Farsi visually. Children or Adults can learn Farsi easily and on their own using Shekkar"
                                                }}
                    </v-card-text>
                  </v-card>
                </v-flex>
                <v-flex xs12 md4>
                  <v-card class="elevation-0 ">
                    <v-card-text class="text-xs-center">
                      <v-icon x-large class="blue--text text--lighten-2">
                         {{ mdiEarth }}
                      </v-icon>
                    </v-card-text>
                    <v-card-title primary-title class="layout justify-center">
                      <div class="headline text-xs-center">
                        {{  
                        this.rtl ?
                         "سراسر دنیا"
                         :
                         "ACROSS THE WORLD"
                        }}

                      </div>
                    </v-card-title>
                    <v-card-text class="flex">
                        {{ this.rtl ?
                          "شکر برای تمام علاقمندان در سرتاسر دنیا در دسترس و رایگان میباشد"
                          :
                          "Shekkar is availabe and free for all who are interested across the world"
                      }}
                    </v-card-text>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
          </v-flex>
        </v-layout>
      </section>

      <section>
        <v-parallax src="images/shekar7_big.png" height="500">
          <v-layout column align-center justify-center>
            <div class="headline black--text mb-3 text-xs-center">
                {{
                  this.rtl ?
                  "پارسی بزرگترین گنجینه تاریخی ماست و وظیفه یک یک ماست تا شرایط یادگیری فارسی را برای فرزندانمان که از مام وطن دور هستند فراهم نماییم"
                  :
                  "Farsi is the greatest cultural treasure for those who speak it. It is our responsiblity to provide means of learning Farsi for our children who grow up afar from motherland."
                }}



            </div>
            <!--
            <em>With the power of Endorfine you don't need to be famous or post pics of cute cats in order to get visibility</em>
            -->
            <v-btn class="blue darken-2 mt-5" dark large :href="farsiLink">
              {{ 
              this.rtl ?
               "با فارسی بیشتر آشنا شوید"
               :
               "Learn more about Farsi"
              }}
            </v-btn>
          </v-layout>
        </v-parallax>
      </section>

      <br>
      <br>
      <br>
<section>
          <v-layout px-4 column align-center justify-center>
            <div class="headline black--text mb-3 text-xs-center">
              {{
                this.rtl ?
                "آیا آماده آغاز یادگیری پارسی هستید؟"
                :
                "Are you ready to learn Farsi in Shekkar?"
              }}
            </div>
            <v-btn class="blue darken-2 mt-5" dark large href="/pre-made-themes">
              {{ 
              this.rtl ?
               "در شکر ثبت نام کنید"
               :
               "Register in Shekkar"
              }}
            </v-btn>
            <br>
            <br>
              <div class="headline black--text mb-3 text-xs-center">
                {{
                  this.rtl ?
                  "دوستانتان را به یادگیری فارسی در شکر دعوت کنید"
                  :
                  "Invite your freind to learn Farsi with you in Shekkar"
                }}
              </div>
              <v-row>
    <v-col class="pa-0" >
      <v-sheet outlined color="transparent">
             <v-card-text class="pa-0">
                    <div  v-if="!subscribed">
                      <v-text-field box :label="emailLabel" :rules="emailRules" v-model="email"  persistent-hint></v-text-field>
                    </div>
                      <!--
                    <div  v-if="!subscribed">
                      <v-text-field box multi-line label="نام دانش آموز به فارسی"></v-text-field>
                    </div>
                  -->
                  </v-card-text></v-sheet>
                  <v-btn class="blue darken-2 mt-5" dark large href="/pre-made-themes">
              {{ 
              this.rtl ?
               "دعوتنامه بفرست"
               :
               "Invite Your Friend"
              }}
            </v-btn>
            <br>
            <br>
            <br>
                </v-col>
              </v-row>
          </v-layout>
      </section>
      <!--
       <section>
        <v-container grid-list-md>
            <v-layout row wrap>
            <v-flex xs12 text-xs-center class="mt-5">
             <h1>
              {{
                this.rtl ?
                "آیا آماده آغاز یادگیری پارسی هستید؟"
                :
                ""
              }}
               <v-btn class="blue darken-2 mb-5" dark large @click="subscribe">
                         بفرست 
                      </v-btn>
             </h1>
             <br>
            <h2>
               برای ایجاد حساب کاربری لطفا اطلاعات خود را در فرم زیر وارد نمایید
            </h2>
            </v-flex>
            <v-flex xs8 offset-xs2>

                <v-card class="elevation-0 transparent">

                  <v-card-text>
                    <v-flex xs12 v-if="!subscribed">
                      <v-text-field box label="پست الکترونیک" :rules="emailRules" v-model="email"  persistent-hint></v-text-field>
                    </v-flex>
                    <v-flex xs12 v-if="!subscribed">
                      <v-text-field box multi-line label="نام دانش آموز به فارسی"></v-text-field>
                    </v-flex>
                    <v-flex xs12 class="text-xs-center" v-if="!subscribed">
                      <v-btn class="blue darken-2 mb-5" dark large @click="subscribe">
                         بفرست 
                      </v-btn>
                    </v-flex>
                     <v-flex xs12 class="text-xs-center" v-if="subscribed">
                      <v-btn class="green lighten-2 mb-5" dark large>Welcome on board!</v-btn>
                    </v-flex>
                  </v-card-text>

               </v-card>

                  </v-flex>
              </v-layout>
            </v-container>
        </section>
-->
       <section>
        <v-parallax src="images/parallax3.jpeg"  height="380">
          <v-layout column align-center justify-center>
            <div class="headline white--text mb-3 text-xs-center">
              {{ 
              this.rtl ?
              "در شبکه های اجتماعی در کنار ما باشید"
              :
              "Follow us on social media"
              }}
            </div>
          </v-layout>
          <v-layout justify-space-around justify-center>

             <v-icon x-large dark> {{ mdiFacebook }} </v-icon>

             <v-icon x-large dark> {{ mdiYoutube }} </v-icon>

             <v-icon x-large dark>{{ mdiInstagram }} </v-icon>
            </v-layout>
        </v-parallax>
      </section>

      <section>
        <v-container grid-list-xl>
          <v-layout row wrap justify-center class="my-5">
            <v-flex xs12 sm4>
              <v-card class="elevation-0 transparent">
                <v-card-title primary-title class="layout justify-center">
                  <div class="headline"> 
                    {{  
                    this.rtl ?
                     "فراخوان همکاری"
                     :
                     "Call For Collaboration"
                    }}
                  </div>
                </v-card-title>
                <v-card-text>
                  {{  
                  this.rtl ?
                  "آیا مایل به همکاری با شکر در زمینههای گوناگون هستید؟" +
                  "به" +
                  " modeer@shekkar.net " +
                   "ایمیل بفرستید"
                  : 
                  "Are you interested to collaborate with us on Shekkar? Email us at modeer@shekkar.net"

                  }}

                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex xs12 sm4 offset-sm1>
              <v-card class="elevation-0 transparent">
                <v-card-title primary-title class="layout justify-center">
                  <div class="headline">
                    {{  
                    this.rtl 
                    ?
                     "درباره شکر"
                     :
                     "About Shekkar"
                    }}
                  </div>
                </v-card-title>
                <v-card-text>
                  {{  
                  this.rtl ?
                  "برای آشنایی بیشتر با ما و داستان شکر این لینک را دنبال کنید"
                  : 
                  "To learn more about us and story of Shekkar, follow this link"
                  }}
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </section>

      <!--
       <section>
        <v-container>
          <v-layout>
            <v-flex xs12 class="text-xs-center">
                <img height="200px" :src="imageLink.logo">
            </v-flex>
          </v-layout>
        </v-container>
     </section>
      -->

    


      <v-footer class="blue darken-2">
        <v-layout row wrap align-center>
          <v-flex xs12 class="text-xs-center">
            <div class="white--text ml-3">
              @copyright 2024, shekkar.net
              <!--
              <v-icon class="red--text">favorite</v-icon>
              using <a class="white--text" href="https://vuetifyjs.com" target="_blank">Vuetify</a>
              -->

            </div>
          </v-flex>
        </v-layout>
      </v-footer>

    </v-content>
    <!--
</v-app>
    -->
</template>

<script>
import { mdiShareVariant  } from '@mdi/js';
import { mdiEyeCheckOutline } from '@mdi/js';
import { mdiEarth } from '@mdi/js';
   import { mdiFacebook } from '@mdi/js';
import { mdiYoutube } from '@mdi/js';
import { mdiInstagram } from '@mdi/js';
import Vuex from 'vuex'
export default {
  name: "App",
  data: function() {
    return {
      mdiShareVariant,
      mdiEyeCheckOutline,
      mdiEarth,
       mdiInstagram,
       mdiYoutube,
       mdiFacebook,
      title: "Endorfine",
      imageLink: {
        main:
          "https://firebasestorage.googleapis.com/v0/b/endorfinevue.appspot.com/o/assets%2Fb13f0434-b228-11e6-8e5d-5252025056ab_web_scale_0.4666667_0.4666667__.jpg?alt=media&token=660df23e-599e-434b-9313-ba69c973eeea",
        sub_main:
          "https://firebasestorage.googleapis.com/v0/b/endorfinevue.appspot.com/o/assets%2FNight-Club-Clubbing-Jobs-Abroad2.jpg?alt=media&token=82bbda7d-5df4-430b-9217-adaf1c8485c5",
        logo:
          "https://firebasestorage.googleapis.com/v0/b/endorfinevue.appspot.com/o/assets%2Fandroid-chrome-512x512.png?alt=media&token=8a0a66f6-4741-4ff6-8f28-eb9ec74374df",
        social_cover:
          "https://firebasestorage.googleapis.com/v0/b/endorfinevue.appspot.com/o/assets%2Fo-NIGHTCLUB-facebook.jpg?alt=media&token=cefc5c4c-9714-41da-9c22-f63caf5e89a4"
      },
      email: "",
      emailRules: [
        v => {
          return !!v || "E-mail is required";
        },
        v =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid"
      ],
      subscribed: false
    };
  },
  methods: {
    subscribe: function() {
      this.subscribed = !this.subscribed;
    }
  },

  computed: {
    ...Vuex.mapState([
      "rtl",
    ]),
    farsiLink() {
      if (this.rtl)
      return "https://fa.wikipedia.org/wiki/%D8%B2%D8%A8%D8%A7%D9%86_%D9%81%D8%A7%D8%B1%D8%B3%DB%8C"
      else 
        return "https://en.wikipedia.org/wiki/Persian_language"

    },
    emailLabel() {
      if (this.rtl)
        return "پست الکترونیک"
      else
        return "email"
    },
    imgHeight: function() {
      var offset = 320;
      console.log("new image height is " + (this.pageHeight - offset));
      return this.pageHeight - offset;
    }
  },

  mounted: function() {
    this.calculateHeight();
  }
};
</script>

<style scoped>
.finedTitle {
  font-weight: 900;
  text-shadow: 2px 2px #000000;
}

.social-icon {
  font-size: 21px;
  color: white;
}
.borders {
   border: 1px solid black;
}
</style>

