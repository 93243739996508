<template>
   <v-container v-if="horoof[0]" fluid>
      <!--
      <img @click="changeToGifOld" :src="imageSrc" > 
      <img src="/images2/gifs/be_koochik_chap.gif" > 
      <img src="/images2/gifs/be_koochik_chap_l.gif" > 
      -->
      <br>

      <div v-if="false" >
         <div  v-for="harf in horoof" :key="harf._id" >
            <img  v-for="harfForm in harf.harfForms" @click="changeToGif($event,harf)" :id="harfForm" :key="harfForm" v-bind:src="'/images2/gifs/'+harfForm+'.gif'" > 
            <div style="padding:300px"> </div>
         </div>
      </div>

      <div v-for="harf in horoof" :key="harf._id + '2'" >
      <img  v-for="harfKey in harf.harfKeys" @click="changeToGifKey($event,harfKey)" :id="harfKey" :key="harfKey" v-bind:src="imgAlph[harfKey]" > 
      </div>

   </v-container>
</template>

<script>

import Vuex from 'vuex'

export default {
   name: 'ShowGifs',
   data: function () {
      return {
         imageSrc: "/images2/gifs/be_koochik_chap.png",
      }
   },
   beforeRouteUpdate(to,from,next) {
      console.log(`R2L : ${this.$vuetify.rtl}`);
      this.$vuetify.rtl = false 
      next()
   },
   watch: {
   },
   async mounted() {
      this.$vuetify.rtl = false
      console.log(`R2L : ${this.$vuetify.rtl}`);
      console.log(process.env)
      this.$vuetify.rtl = false 
      //const email = "yazdan.aghaghiri@gmail.com"
      //const code = "1111"
      //await this.login({code,email})
     //await this.getStudent({});
      await this.getHoroof()

   },
   computed: { 
     ...Vuex.mapState("harf",[ 
        "horoof",
         "imgAlph",
        ])

   },
   methods: { 
      changeToGifOld() {
         console.log("here")
        this.imageSrc="/images2/gifs/be_koochik_chap.gif" 
      },
     //changeToGif(x,harf) {
     //   console.log(x)
     //   const elems = document.getElementById(harf.harfLead)
     //   console.log(elems)
     //   console.log(harf)
     //   for ( const elem in elems ) {
     //      const oldSrc = elem.getAttribute("src")
     //      console.log(oldSrc)
     //      const newSrc =  oldSrc.replace(".png", ".gif") 
     //      console.log(newSrc)
     //      elem.setAttribute("src", `/images2/gifs/${elem.id}.gif`)
     //   }
     //}
      changeToGif(x) {
         const oldSrc = x.target.src
         const newSrc =  oldSrc.replace(".png", ".gif") 
         console.log(newSrc)
         x.target.src = `/images2/gifs/${x.target.id}.gif`
      },
      async changeToGifKey(x,harfForm) {
         const origSrc = x.target.src
         x.target.src = `/images2/gifs/${harfForm}.gif`
         setTimeout(() => {
            x.target.src = origSrc 
         }, "5000")

      },
     ...Vuex.mapActions("student",[
        "getStudent",
        ]),
      ...Vuex.mapActions("harf",[
         "getHoroof",
      ]),
      ...Vuex.mapActions("auth",[
         "login",
      ]),
   }
}
</script>

<style scoped>
img {
  border: 5px solid #555;
}
</style>
