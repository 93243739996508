import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';

Vue.use(Vuex)

const state=  {
   // admin only
   adminStudents: [],
   adminKelases: [],
   adminKalamehs: [],

}
const mutations= {
   accessToken(state, newToken) {
      state.accessToken = newToken
      localStorage.setItem('user-token', newToken)
   },
   adminKelases(state, adminKelases ) { 
      state.adminKelases = adminKelases
   },
   adminStudents(state, adminStudents ) { 
      state.adminStudents = adminStudents
   },
   adminKalamehs(state, adminKalamehs ) { 
      state.adminKalamehs = adminKalamehs
   },

}
const actions= {
   async getKelases({commit, rootState, rootGetters}) {
      const res = await axios.get(`${rootState.auth.backendHost}/api/kelases`, rootGetters["auth/headerConfig"])
      const adminKelases = res.data
      commit("adminKelases", adminKelases)
   },
   async getKalamehs({commit,rootState, rootGetters}) {
      const res = await axios.get(`${rootState.auth.backendHost}/api/kalamehs`, rootGetters["auth/headerConfig"])
      const adminKalamehs = res.data
      commit("adminKalamehs", adminKalamehs)
   },
   async getStudents({commit,rootState, rootGetters},params) {
      console.log(params)
      console.log(state.studentId)
      const res = await axios.get(`${rootState.auth.backendHost}/api/students?populate=1`, rootGetters["auth/headerConfig"])
      const adminStudents = res.data
      for ( let student of adminStudents ) {
         if ( student.profileImage ) {
            let blob = new Blob([ new Buffer(student.profileImage.data, 'base64')], { type: 'image/jpg' });
            let url = window.URL.createObjectURL(blob)
            student.url = url
         }
      }
      commit("adminStudents", adminStudents)
   },
}

const getters = {
}

export default{
   namespaced: true,
   state,
   actions,
   mutations,
   getters,
}

