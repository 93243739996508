import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';
//import pn from 'persian-number';
import _ from 'lodash'
//import {  base64StringToBlob } from 'blob-util'

Vue.use(Vuex)

const state=  {
   student: {
      name: '',
      naam: '',
      kelases: [],
      //darsId: null,
      studentId: null, 
   },
   
   // all defined in context of current kelas
   numHarfLearned: 0,
   studentKelasId: null,
   kelasDarsId: null,
   stuKelId: null,

   // All defined in context of current Dars
   // Audio/Image/darsId of current Dars being looked at 
   currDars: null,
   audioDars: null,
   currDarsId: null,  
   reviewDarses: [],
   currReviewPageId: null,
   audioDarses: {},
}
const mutations= {
   numHarfLearned( state, numHarfLearned) {
      state.numHarfLearned = numHarfLearned
   },
   incrStuDarsId(state) {
      //state.student.darsId++
      state.kelasDarsId++
   },
   kelasDarsId(state, kelasDarsId) {
      state.kelasDarsId = kelasDarsId
   },
   stuKelId(state, stuKelId) {
      state.stuKelId = stuKelId
   },
   currDars(state,currDars) {
      state.currDars = currDars
   },
   reviewDarses(state,reviewDarses) {
      state.reviewDarses = reviewDarses
   },
   audioDars(state,audioDars) {
      state.audioDars = audioDars
   },
   audioDarses(state,audioDarsesLoc) {
      state.audioDarses = audioDarsesLoc
   },
  currDarsId (state, currDarsId) {
     state.currDarsId = currDarsId
  },
   studentKelasId (state, studentKelasId) {
      state.studentKelasId = studentKelasId
   },
   accessToken(state, newToken) {
      state.accessToken = newToken
      localStorage.setItem('user-token', newToken)
   },
   studentId(state, studentId) {
      state.studentId = studentId
      localStorage.setItem('student-id', studentId)
   },
   student(state, student ) { 
      state.student = student
   },
   currReviewPageId(state, currReviewPageId ) { 
      state.currReviewPageId = currReviewPageId
   },

}
const actions= {
   async getStudent({commit,rootState, rootGetters},params) {
      console.log(params)
      console.log(state.studentId)
      // TODO: better to changes this to mongodb _id
      const res = await axios.get(`${rootState.auth.backendHost}/api/students/${rootState.auth.studentId}`, rootGetters["auth/headerConfig"])
      const student = res.data
      let blob = new Blob([ new Buffer(student.profileImage.data, 'base64')], { type: 'image/jpg' });
      let url = window.URL.createObjectURL(blob)
      student.imgProfile  = url
      commit("student", student)
   },
   async getStudentKelas({commit,rootState, rootGetters},params) {
      try {
      console.log(params)
      console.log(state.studentId)
      // TODO: better to changes this to mongodb _id
      const res = await axios.get(`${rootState.auth.backendHost}` + 
                                  `/api/students/${rootState.auth.studentId}/kelas/${rootState.auth.kelasId}`, rootGetters["auth/headerConfig"])
      const student = res.data
      if (student.profileImage) {
         let blob = new Blob([new Buffer(student.profileImage.data, 'base64')], { type: 'image/jpg' });
         let url = window.URL.createObjectURL(blob)
         student.imgProfile = url
      }
      //const kelasDarsId = student.kelasDarsIds.find(k => k.kelasId.toString() === rootState.auth.kelasId ).darsId
      const kelasDarsId = student.stuKelas.darsId
      commit("student", student)
      commit("kelasDarsId", kelasDarsId )
      commit("stuKelId", student.stuKelas._id )
      commit("studentKelasId", rootState.auth.kelasId)
      } catch (e) {
         console.log('Error is' + e)
         window.location.href = "/ui/login"
      }
   },
   async getStudents({commit,rootState, rootGetters},params) {
      console.log(params)
      console.log(state.studentId)
      const res = await axios.get(`${rootState.auth.backendHost}/api/students`, rootGetters["auth/headerConfig"])
      const students = res.data
      for ( let student of students ) {
         if ( student.profileImage ) {
            let blob = new Blob([ new Buffer(student.profileImage.data, 'base64')], { type: 'image/jpg' });
            let url = window.URL.createObjectURL(blob)
            student.url = url
         }
      }

      commit("students", students)
   },
   async getNumHarfLearned({commit,rootState, rootGetters}) {
      const res = await axios.get(`${rootState.auth.backendHost}/api/darses/${state.kelasDarsId + 1}`,
         rootGetters["auth/headerConfig"]);
      let dars = res.data
      commit("numHarfLearned", dars.numHarfLearned ); 
   },
   async getDars({commit,rootState, rootGetters},params) {

      let dars
      if (params.currDarsId != "latest" ) {
         const paramKelasDarsId =  parseInt(params.currDarsId) 
         //const kelasDarsId = state.student.kelasDarsIds.find(k => k.kelasId.toString() === rootState.auth.kelasId ).darsId
         if ( paramKelasDarsId <= state.kelasDarsId + 2 ) { 

            if ( paramKelasDarsId === state.kelasDarsId + 2 ) {
               //state.student.kelasDarsIds.find(k => k.kelasId.toString() === state.studentKelasId ).darsId++
               //await axios.put(`${rootState.auth.backendHost}/api/students/${state.student._id}`, 
               //   state.student , rootGetters["auth/headerConfig"])
               state.student.stuKelas.darsId++
               await axios.put(
                  `${rootState.auth.backendHost}/api/students/${state.student._id}/studentKelas/${state.student.stuKelas._id}`, 
                  state.student.stuKelas , rootGetters["auth/headerConfig"])
               commit("incrStuDarsId")
            }
            let res = await axios.get(`${rootState.auth.backendHost}/api/darses/${paramKelasDarsId}`, 
               rootGetters["auth/headerConfig"] );
            console.log("====================")
             dars = res.data
            commit('currDarsId',dars.darsId)
            commit("currDars", dars);

         } else {
            // TODO : throw some error
         }
      } else {
         const res = await axios.get(`${rootState.auth.backendHost}/api/darses/${state.kelasDarsId + 1}`,
            rootGetters["auth/headerConfig"]);
         dars = res.data
         commit("currDars", dars);
         commit('currDarsId',dars.darsId)
      }

      if ( dars  && dars.darsType === 'KALAMEH' && dars.kalameh.kalamehAudio.data ) {
         const url = await getAudioUrl(dars.kalameh.kalamehAudio.data)
         commit("audioDars", url);
      }
   },
   //this is probably not used
   async getReviewDarses({commit,rootGetters, rootState},params) {
      const reviewDarsesPageId = params.pageId
      const pageSize = params.pageSize
      console.log("currReviewPageId: " + reviewDarsesPageId)
      const res = await axios.get(`${rootState.auth.backendHost}/api/darses?page=${reviewDarsesPageId}&pageSize=${pageSize}`, rootGetters["auth/headerConfig"])
      const reviewDarses = res.data
      commit("reviewDarses", reviewDarses);
      commit("currReviewPageId", reviewDarsesPageId );
      const audioDarsesLoc = {} 
      for ( let dars of reviewDarses ) {
         if ( dars && dars.kalameh && dars.kalameh.kalamehAudio && dars.kalameh.kalamehAudio.data ) {
            const url = await getAudioUrl(dars.kalameh.kalamehAudio.data)
            audioDarsesLoc[dars.kalameh.kalameh] = url 
         }
      }
      commit("audioDarses",audioDarsesLoc)
   },
   // TODO  5/23/22: refactor ( the real difference with prev func is pageSize params )
   async getReviewDarsesHarf({commit,rootGetters, rootState},params) {
      const reviewDarsesPageId = params.pageId
      console.log(reviewDarsesPageId)
      const res = await axios.get(`${rootState.auth.backendHost}/api/darses?page=${reviewDarsesPageId}`, rootGetters["auth/headerConfig"])
      const darses = res.data
      const reviewDarses = darses.filter(dars => dars.kalameh)
      commit("reviewDarses", reviewDarses);
      commit("currReviewPageId", reviewDarsesPageId );
      const audioDarsesLoc = {} 
      for ( let dars of reviewDarses ) {
         if ( dars && dars.kalameh.kalamehAudio && dars.kalameh.kalamehAudio.data ) {
            const url = await getAudioUrl(dars.kalameh.kalamehAudio.data)
            audioDarsesLoc[dars.kalameh.kalameh] = url 
         }
      }
      commit("audioDarses",audioDarsesLoc)
   },
  //async incrStuDarsId({commit,rootGetters, rootState}) {

  //   const res = await axios.get(`${rootState.auth.backendHost}/api/students/${state.student.studentId}`, rootGetters["auth/headerConfig"])
  //   const stu = res.data
  //   stu.darsId++
  //   await axios.put(`${rootState.auth.backendHost}/api/students/${state.student._id}`, stu , rootGetters["auth/headerConfig"])
  //   commit('incrStuDarsId')
  //}
  //async getDars(context, darsId) {
  //   const res = await axios.get(`${rootState.auth.backendHost}/api/darses/${darsId}`,state.headerConfig);
  //   const dars = res.data
  //   context.commit("dars", dars)
  //}

}

const getAudioUrl = async (data) => {
   const blob = new Blob([ new Buffer(data, 'base64')], { type: 'audio/m4a' });
   const url = window.URL.createObjectURL(blob)
   //console.log(url)
   //window.audio.src = url;
   //window.audio.play();
   return url 
  //this.audioDars[dars.kalameh] = url
  //console.log("HERE  " + url)
}

const getters = {
   currDarsNumHarf(state) {
      if (state.currDars)
         return state.currDars.numHarfLearned
      else 
         return 0
   },
   studentKelases(state) {
      console.log(state.student.kelases)
      return state.student ? state.student.kelases.map(a=>a.kelasName): []
   },
   studentKelas(state) {
      ///const kelas = state.student && state.student.kelases ? state.student.kelases.find(a=>a._id === state.studentKelasId ) : null
      //return kelas ? kelas.kelasName : ''
      const kelasName = state.student && state.student.stuKelas ? state.student.stuKelas.kelas.kelasName : ""
      return kelasName
   },
   darsKalameh() {
      return state.currDars && state.currDars.kalameh ? state.currDars.kalameh['kalameh'] : ''
   },
  darsHarfKeys() {  
    return state.currDars && state.currDars.kalameh ? state.currDars.kalameh['kalamehHarfKeys'] : [] 
  },
 //darsHarfForms(state,getters,rootState) {
 // const horoof = rootState.harf.horoof
 //  if (horoof.length == 0 ) return []
 //  const darsHarfKeys = state.currDars ? state.currDars['kalamehHarfKeys'] : []
 //  const darsHarfForms = darsHarfKeys.map(hk => {
 //    const harf = horoof.find(h => h.harfKeys.includes(hk))
 //    const idx = harf.harfKeys.findIndex(hk)
 //    return harf.harfForms[idx]
 //  })
 //  return darsHarfForms
 //},
  darsHoroof(state,getters,rootState) {
    if (rootState.harf.horoof.length == 0 ) return []
    let harfHash = {}
    const darsHarfKeys = state.currDars && state.currDars.kalameh ? state.currDars.kalameh['kalamehHarfKeys'] : []
    const darsUniqueHarfForms = darsHarfKeys.filter((value, index, self) => {return self.indexOf(value) === index; })
    let darsHarfs = darsUniqueHarfForms.reduce((tot,harfForm) => { 
      let harf = rootState.harf.horoof.find( g => g.harfKeys.includes(harfForm))
      if ( harfHash[harf.harfName] ) return tot 
      tot.push(harf)
      harfHash[harf.harfName] = 1
      return tot
    }, [])
    return darsHarfs
  },
  goToDarses(state,getters,rootState) {
     if ( !state.student || !rootState.harf ||  rootState.harf.horoof.length ===0 || !state.numHarfLearned ) return []
     const learnedHarfs = rootState.harf.horoof.slice(0,state.numHarfLearned)
     
     //const learnedHarfs = rootState.harf.horoof  // show everything bug as disabled
     const retArr = Array.from(Array(learnedHarfs.length).keys()).map( a => { 
        return { 
           //text: `${learnedHarfs[a].harfF} ( ${pn.convertEnToPe(learnedHarfs[a].startingDars)} )`,
           text: `${learnedHarfs[a].harfF}`,
           value: learnedHarfs[a].startingDars
        }
     })
     // manually adjust for the only exception
    //retArr[0].text = `${learnedHarfs[0].harfF},${learnedHarfs[1].harfF}`
    //retArr.splice(1,1)
    
     return _.sortBy(retArr,['value'])
  },
   
}

export default{
   namespaced: true,
   state,
   actions,
   mutations,
   getters,
}

