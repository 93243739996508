<template>
   <v-container style="border-top:3px; max-width: 1200px;background-color:#E9DCC6"   class="my-2 px-1 bx-1">
      <div v-if="noRender">
         <h1>
            این صفحه قابل نمایش نیست
         </h1>
         <h2>
            ۱. پهنای مرورگر را افزایش دهید یا

         </h2>
         <h2>
            ۲. دستگاه خود را در حالت افقی نگهدارید
         </h2>
      </div>
      <div v-else>
            <v-row no-gutters :align="center" style="height:50px" class="mb-1">
               </v-row>
         <v-row  :align="center" class="mb-1">
            <v-col lg="9" md="8" sm="7" class="bx-2">
               <!--<img id="ear" style="display:none" src="images/ear.jpg" >  -->
               <canvas  id="myCanvas" class="mx-0" style="'border-top': 1px solid black; float: center">
               </canvas>
               <div  id="allImages">
                  <div v-for="(aG,aIdx) in horoof" :key="aIdx" >
                     <img v-for="alpha in aG['harfKeys']" :key="alpha" style="display:none"  v-bind:id="alpha" v-bind:src="imgAlph[alpha]"> 
                     <img id="ear" style="display:none" src="/images/ear.jpg" > 
                  </div>
               </div>

            </v-col>
            <v-col lg="3" md="4" sm="5" id="navColumn">
               <left-panel 
                                        :type="'alefba'"

                            > 
               </left-panel>
            </v-col>
         </v-row>
      </div>
   </v-container>
</template>

<script>
const cons = require('../constants.js');
const paper =  require('paper');
import { utils }  from '../class';
//import { mdiHome, mdiChevronLeft, mdiChevronRight, mdiArrowRightBold, mdiArrowLeftBold } from '@mdi/js';
//import { mdiViewQuilt, mdiViewHeadline, mdiChevronLeft, mdiChevronDoubleLeft, mdiChevronRight, mdiChevronDoubleRight  } from '@mdi/js';
import LeftPanel from './LeftPanel.vue';
import Vuex from 'vuex'


export default {
   name: 'Alefba',
   components: {
      LeftPanel,
   },
   data: function () {
      return {
         noRender: false,
         canvas: null,
      }
   },
  watch: {

  },
  async mounted() {

     this.changeRTL()
     const height = this.checkRes()

     await this.getStudentKelas({});
     await this.getKelas({kelasId: this.studentKelasId})

     await this.getLatestDars()
     await this.getHoroof();

     await this.getLatestDars()
     await this.getNumHarfLearned()

      this.initCanvas(height)
      const canvasWait = 500
      setTimeout ( () => { 
         console.log("WAIT for Canvas " + canvasWait)
         console.log("leanred Horoof " + this.learnedHoroof)
         
         utils.updateCanvasHoroof(this.learnedHoroof)
      }, canvasWait) 
      //} catch (e) {
      //  console.log(e)
      //  this.$router.push('/')
      //}
   },
  computed: { 

    ...Vuex.mapState("student",[ 
       "numHarfLearned",
       "student",
       "darsId",
       "kelases",
        "studentKelasId",
    ]),
     ...Vuex.mapState("harf",[ 
        "horoof",
        "audioAlph",
        "imgAlph",
     ]),
     ...Vuex.mapGetters("student",[
        // ...
     ]),
     learnedHoroof() {
        return this.horoof.slice(0,this.numHarfLearned)
     },


  },
  //
  methods: {
     ...Vuex.mapActions("student",[
        "getStudentKelas",
        "getDars",
        "getNumHarfLearned",
        "setDarsId",
     ]),
     ...Vuex.mapActions("kelas",[
        "getKelas",
     ]),
     ...Vuex.mapActions("harf",[
        "getHoroof",
     ]),
     async getLatestDars() {
        this.getDars({darsId: "latest"})
        //this.addDarsAudio();
     },
     checkRes() {
        if ( window.innerWidth < 800 ) {    
           this.noRender = true
           return
        }
        const height = document.getElementById("navColumn").offsetHeight
        console.log("Height: " + window.innerHeight + ",Width: " + window.innerWidth)
        //const height = window.innerHeight - 110 
        console.log(`Mounted with height ${height}`)
        return height
     },
    changeRTL () {
      this.$vuetify.rtl = true
    },

    async goToAllDarses() {
      this.$router.push({name: 'all_darses' , query: { page:1 }  })
    },
    async initCanvas(height) {
      this.canvas = document.getElementById('myCanvas');
       this.canvas.style.backgroundColor = 'white'
      //this.canvas.width =   cons.canvasWidth; // window.innerWidth
      //this.canvas.height = 8000 // cons.canvasHeight; // window.innerHeight
      console.log(cons)
      this.canvas.height = height; // window.innerHeight
      this.canvas.width = this.canvas.parentElement.clientWidth -10; // window.innerHeight
      console.log("canvas width: " + this.canvas.width )
      console.log("canvas height: " + this.canvas.height)
      await paper.setup(this.canvas);
      // scalaing like this wont fix RtoL issue
      // const canvasContext = canvas.getContext('2d');
      // canvasContext.translate(window.innerWidth, 0);
      // canvasContext.scale(-1,1 );
      // canvasContext.save()

      //this line eliminates need to access everything through paper object
      // but as a sideeffect will impact global scope for example breaks browsersync

     //if ( this.canvas.width < 800 ) {
     //  php.phiSide = 40
     //}
    },
    async goToDarses() {
      this.$router.push({name: 'main',params: {darsId:'latest'}})
    },
    async clearCanvas() {
      paper.project.activeLayer.removeChildren();
      paper.view.draw();
    },
    
  }
}
</script>

<style scoped>
.disable-btn {
  pointer-events: none;
  opacity: 0.2;
}
a {
    text-decoration: none;
}
</style>
