<template>
   <v-container fluid>
      <v-row class="mt-10 text-center">
         <v-col cols="3">
         </v-col>
      </v-row>

      <v-row class="mt-10 text-center">
         <v-data-table :headers="headers" :items="adminKalamehsFiltered" :items-per-page=20 :footer-props="{
                              'items-per-page-options': [500, 100, 1000, -1 ],
                              }" class="elevation-1">
               <!--
            <template v-slot:body="{ items }">
               <tbody>
                  <tr :class="pickColor(item.harfDarsIdx) " 
                     v-for="item in items" :key="item.kalamehIdx">
                     <td>{{ item.kalamehIdx }}</td>
                     <td> <h3> {{ item.kalameh }}</h3></td>
                     <td><h3>{{ item.kalamehFarsi }}</h3></td>
                     <td>{{ item.harfDarsIdx }}</td>
                     <td>{{ item.kalamehAudio ? "Yes" : "No" }}</td>
                  </tr>
               </tbody>
            </template>
            -->
            <template v-slot:item.kalameh="{ item }">
               <h2> {{ item.kalameh }} </h2>
            </template>
            <template v-slot:item.kalamehFarsi="{ item }">
               <h2> {{ item.kalamehFarsi }} </h2>
            </template>
            <template v-slot:item.fileName="{ item }">
               <h2> {{ item.kalameh + '.mp3' }} </h2>
            </template>
            <template v-slot:item.hasAudio="{ item }">
               <h2 v-if="item.kalamehAudio.data" :style="'background-color:' + ( item.kalamehAudio.data ? 'gray' : 'red')" > 
               <audio controls>
                  <source :src="'/audios/kalamehs/' + item.kalameh+ '.mp3'">  
               </audio>
               </h2>
            </template>
            <template v-slot:item.darsInfo="{ item }">
               <h2 :class="pickColor(item.darsHarfIdx)">  {{ item.darsHarf }} </h2>
            </template>

         </v-data-table>
      </v-row>
   </v-container>
</template>

<script>

import { mdiHome } from '@mdi/js';
import Vuex from 'vuex'
import Lodash from 'lodash'                  

export default {
   name: 'AdminKalamehs',
   data: function () {
      return {
         imageData: "",
         missingAudio: false,
         dialog: false,
         dialogDelete: false,
         selKelas: null,
         pageItems: [],
         headers: "kalamehIdx fileName kalamehFarsi darsInfo hasAudio".split(' ').map( a => {  return { 'text': a, 'value': a }} ),
         editedIndex: -1,
         mdiHome,
         editedItem: {
            name: '',
            naam: '',
            darsId: 0,
            email: '',
            code: 0,
            profileImage: "", 
            needToSave: false,
            kelasDarsIds: null,
         },
      }
   },
   beforeRouteUpdate(to,from,next) {
      console.log(`R2L : ${this.$vuetify.rtl}`);
      this.$vuetify.rtl = false 
      next()
   },
   watch: {
      async selKelas (val ) {
         await this.getKelas({kelasId: val})
      },
      dialog (val) {
         val || this.close()
      },
   },
   async mounted() {
      console.log(`R2L : ${this.$vuetify.rtl}`);
      console.log(process.env)
      this.$vuetify.rtl = false 
      const email = "yazdan.aghaghiri@gmail.com"
      const code = "1111"
      this.missingAudio = this.$route.query.missingAudio ? true : false
      await this.login({code,email})
      await this.getKalamehs()
         //map( a=> { return { text: a.kelasName, value: a._id}} )

   },
   computed: { 
      ...Vuex.mapState("student",[
         "studentKelasId",
      ]),
      ...Vuex.mapState("admin",[
         "adminKelases",
         "adminStudents",
         "adminKalamehs",
      ]),
      ...Vuex.mapState("harf",[
         "horoof"
      ]),
      ...Vuex.mapGetters("auth",[
         "headerConfig",
      ]),
      ...Vuex.mapState("auth",[
         "backendHost",
      ]),
      formTitle () {
         return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },
      adminKalamehsFiltered() {
         return this.missingAudio ? 
            this.adminKalamehs.filter(x => !x.kalamehAudio.data  ) :
            this.adminKalamehs 
      }
   },
   methods: { 
      ...Vuex.mapActions("auth",[
         "login",
      ]),
      ...Vuex.mapActions("admin",[
        "getKalamehs",
      ]),
      pickColor(x) {
         if ( x === 1 ) return 'harf-start'
         if ( x > 8 ) return 'lowlight-notused'
         return 'harf-dars'  
      },
      onFileInfo(file) {
         console.log(file)
      },
      getKelasDarsId(item,idx) {
         if (!item.kelasDarsIds ) return "N/A"
         return Lodash.get(item.kelasDarsIds.find(k => k.kelasId === item.kelases[idx] ),"darsId","N/A")
      },
      kelasIdToNames ( ids ) {
         console.log(ids)
         if ( !ids || !this.adminKelases ) return []
         return ids.filter(a=> a).map ( k  => { 
            const studentKelas = this.adminKelases.find( j => j._id == k )
            if (!studentKelas ) return ''
            console.log(studentKelas )
            return studentKelas.kelasName 
         })
      },


   }
}
</script>

<style scoped>

.harf-start {
   background-color: green
}
.harf-dars {
   background-color: yellowgreen
}
.lowlight-notused {
   background-color: grey
}

</style>
