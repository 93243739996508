<template>
<v-container>
      <!--
   <v-btn @click="changeRTL"> change R2L </v-btn>
      -->
      <br>
         <div>
            <router-link to="/ui/as"> admin students </router-link>
         </div>
         <div>
            <router-link to="/ui/ak"> admin kelases </router-link>
         </div>
         <div>
            <router-link to="/ui/aw"> admin kalamesh </router-link>
         </div>
         <div>
            <router-link to="/ui/gifsall"> show all gifs ( clickable ) </router-link>
         </div>
         <div>
            <router-link to="/ui/gifsallinf"> show all gifs infinity </router-link>
         </div>
         <div>
            <router-link to="/ui/gifs"> show all gifs static </router-link>
         </div>
         <div>
            <a href="/font.html">font ( lorem ipsum )</a>
         </div>
         <div>
            <a href="/font2.html"> font2 ( arabic ) </a>
         </div>

<v-row class="text-center">
<v-col cols="6">
   <div>
      Window inner Width: {{ this.innerWidth }}
   </div>
   <div>
      Window inner Heigth: {{ this.innerHeight }}
   </div>
   <div>
      sizeScreenH : {{ this.sizeScreenW() }}
   </div>
   <div>
      sizeScreenW : {{ this.sizeScreenH() }}
   </div>
</v-col>

<v-col cols="6"> 
      <div>
         Mobile Check : {{ this.mobileCheck() }}
      </div>
      <div>
         User Agent : {{ this.browserVersion1b() }}
      </div>
      <div>
         Browswer platform : {{ this.browserPlatform() }}
      </div>
      <div>
         Browser Engine: {{ this.browserEngine() }}
      </div>

</v-col>

</v-row>
<v-row>
 </v-row>

</v-container>
</template>

<script>

//import axios from 'axios'

export default {
  name: 'User',
  data: function () {
    return {
       innerWidth: 0,
       innerHeight: 0
    }
  },
  watch: {
  },
  async mounted() {
      this.$vuetify.rtl = false 
    
   //console.log("KJKJKJKJ")
   //const res = (await axios.get('http://localhost:3085/users'))
   //  console.log(res)
   //  this.students = res['data']
   //console.log(res['data'])

  },
  computed: { 
  },
  methods: { 
     checkRes() {
        this.innerWidth = window.innerWidth
        this.innerHeight = window.innerHeight
        
       if (  window.innerWidth < window.innerHeight ) {    
          this.noRender = true
          return
       }
        //this.height = Math.max(600, document.getElementById("navColumn").offsetHeight )

        console.log("Height: " + window.innerHeight + ",Width: " + window.innerWidth)
        //const height = window.innerHeight 
        console.log(`Mounted with height ${this.height}`)
        //return this.height
     },
      mobileCheck() { 
         let check = false;
         const a = navigator.userAgent 
         const toMatch = [
            /Android/i,
            /webOS/i,
            /iPhone/i,
            /iPad/i,
            /iPod/i,
            /BlackBerry/i,
            /Windows Phone/i
         ];
         check =  toMatch.some((toMatchItem) => {
            return a.match(toMatchItem);
         });
         return check 

      },
     //timeOpened:new Date(),

    //timezone:(new Date()).getTimezoneOffset()/60,

    pageon(){return window.location.pathname},
    referrer(){return document.referrer},
    previousSites(){return history.length},

    browserName(){return navigator.appName},
    browserEngine(){return navigator.product},
    browserVersion1a(){return navigator.appVersion},
    browserVersion1b(){return navigator.userAgent},
    browserLanguage(){return navigator.language},
    browserOnline(){return navigator.onLine},
    browserPlatform(){return navigator.platform},
    javaEnabled(){return navigator.javaEnabled()},
    dataCookiesEnabled(){return navigator.cookieEnabled},
    dataCookies1(){return document.cookie},
    dataCookies2(){return decodeURIComponent(document.cookie.split(";"))},
    dataStorage(){return localStorage},

    sizeScreenW(){return screen.width},
    sizeScreenH(){return screen.height},
    sizeDocW(){return document.width},
    sizeDocH(){return document.height},
    sizeInW(){return innerWidth},
    sizeInH(){return innerHeight},
    sizeAvailW(){return screen.availWidth},
    sizeAvailH(){return screen.availHeight},
    scrColorDepth(){return screen.colorDepth},
    scrPixelDepth(){return screen.pixelDepth},


   //latitude(){return position.coords.latitude},
   //longitude(){return position.coords.longitude},
   //accuracy(){return position.coords.accuracy},
   //altitude(){return position.coords.altitude},
   //altitudeAccuracy(){return position.coords.altitudeAccuracy},
   //heading(){return position.coords.heading},
   //speed(){return position.coords.speed},
   //timestamp(){return position.timestamp},


   //studentRoute(student) {
   //  if ( student === 'Yara' ) {
   //    return `/dars/8318?email=yazdan.aghaghiri@gmail.com`
   //  } else {
   //    return `/dars/8173?email=yazdan.aghaghiri@gmail.com`
   //  }
   //}
  }
}
</script>
